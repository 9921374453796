const { DateTime } = require("luxon");

export const capitalize = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
export const convertUtcToLocal = (utcTime: string): string => {
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localTime = DateTime.fromISO(utcTime, { zone: "utc" })
    .setZone(currentTimeZone)
    .toLocal();
  return localTime.toJSDate().toLocaleString();
};
export const roundToTwoDecimalPoints = (value: number): string => {
  return value.toFixed(2);
};
export const getFormattedDate = (
  date: string,
  format = "dd/MM/yyyy",
): string => {
  const luxonDate = DateTime.fromISO(date);
  return luxonDate.toFormat(format);
};
