import RefreshIcon from "@mui/icons-material/Refresh";
import { FormControl, FormLabel, IconButton, Stack } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import React, { useState } from "react";

interface CreateOrganizationModalProps {
  open: boolean;
  onClose: () => void;
  onCreateOrganization: (
    name: string,
    intactId: string,
    secret: string,
  ) => void;
}

const CreateOrganizationModal: React.FC<CreateOrganizationModalProps> = ({
  open,
  onClose,
  onCreateOrganization,
}) => {
  const generateRandomPassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*-_+=";
    let password = "";
    for (let i = 0; i < 32; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters[randomIndex];
    }
    return password;
  };

  const [name, setName] = useState("");
  const [intactId, setIntactId] = useState("");
  const [secret, setSecret] = useState(generateRandomPassword());

  const nameError = name === "";
  const intactIdError = intactId === "";
  const secretError = secret === "";

  const clearFields = () => {
    setName("");
    setIntactId("");
    setSecret(generateRandomPassword());
  };

  const handleCreateClick = () => {
    onCreateOrganization(name, intactId, secret);
    onClose();
    clearFields();
  };

  // Validation and button disabling logic
  const isCreateButtonDisabled = name.trim() === "" || intactId.trim() === "";

  // Event handlers
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    setName(newName);
  };

  const handleIntactIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newIntact = event.target.value;
    setIntactId(newIntact);
  };

  const handleSecretChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSecret = event.target.value;
    setSecret(newSecret);
  };
  const regenerateSecret = () => {
    setSecret(generateRandomPassword());
  };
  const handleClose = () => {
    onClose();
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          minWidth: 300,
        }}
      >
        <FormControl>
          <Stack spacing={2} width={"25vW"}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                onChange={handleNameChange}
                error={nameError}
                value={name}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Intact ID</FormLabel>
              <Input
                onChange={handleIntactIdChange}
                error={intactIdError}
                value={intactId}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Secret</FormLabel>
              <Input
                onChange={handleSecretChange}
                error={secretError}
                value={secret}
                endDecorator={
                  <IconButton onClick={regenerateSecret}>
                    <RefreshIcon />
                  </IconButton>
                }
              />
            </FormControl>
          </Stack>
        </FormControl>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="outlined"
            color="danger"
            onClick={handleClose}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button onClick={handleCreateClick} disabled={isCreateButtonDisabled}>
            Create
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateOrganizationModal;
