import { Box, Button, Skeleton, Stack, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import ClientConfigurationsTable from "../components/ClientConfiguration/ClientConfigurationsTable";
import ConfirmSaveChangesModal from "../components/common/ConfirmSaveChangesModal";
import { ClientConfiguration } from "../models/ClientConfiguration/ClientConfiguration";
import ClientConfigurationsRepository from "../repositories/ClientConfigurationsRepository";
interface ClientConfigurationsPageProps {
  organizationId?: string;
}
const ClientConfigurationsPage: React.FC<ClientConfigurationsPageProps> = ({
  organizationId,
}) => {
  const [saveChangesModalOpened, setSaveChangesModalOpened] =
    useState<boolean>(false);
  const [dirtyConfigs, setDirtyConfigs] = useState<{
    configs: ClientConfiguration[];
    isFaulty: boolean;
  }>({ configs: [], isFaulty: false });
  const [configs, setConfigs] = React.useState<ClientConfiguration[]>([]);
  const [deleteConfigId, setDeleteConfigId] = useState<string | null>(null);
  const configurationsRepository = new ClientConfigurationsRepository();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const configMutation = useMutation(
    ["clientConfigurations", organizationId],
    async () => {
      dirtyConfigs.configs.map(async (updatedConfig) => {
        return await configurationsRepository.updateClientConfiguration(
          updatedConfig.id || "",
          updatedConfig,
        );
      });
      setDirtyConfigs({ configs: [], isFaulty: false });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["clientConfigurations", organizationId]);
      },
    },
  );
  const configurationsQuery = useQuery(
    ["clientConfigurations", organizationId],
    async () => {
      const configurations =
        await configurationsRepository.getClientConfigurations(
          organizationId || "",
        );
      return configurations;
    },
    {
      staleTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchIntervalInBackground: true,
    },
  );
  useEffect(() => {
    if (configurationsQuery.data) {
      setConfigs(configurationsQuery.data);
    }
  }, [organizationId, configurationsQuery.data]);
  const { isLoading, isError } = configurationsQuery;
  const handleConfirmDelete = async () => {
    if (deleteConfigId) {
      try {
        await configurationsRepository.deleteConfig(deleteConfigId);
        queryClient.invalidateQueries(["clientConfigurations", organizationId]);
      } catch (error) {
        console.error(error);
      }
    }
    setDeleteConfigId(null);
    setSaveChangesModalOpened(false);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Client Configurations</Typography>
        <Stack direction="row" spacing={1}>
          <Skeleton variant="inline" loading={isLoading || isError}>
            <Button
              color="primary"
              size="sm"
              disabled={
                dirtyConfigs.configs.length === 0 || dirtyConfigs.isFaulty
              }
              sx={{ pr: "2" }}
              onClick={async () => await configMutation.mutate()}
            >
              Save Changes
            </Button>
          </Skeleton>
          <Skeleton variant="inline" loading={isLoading || isError}>
            <Button
              color="primary"
              size="sm"
              onClick={() =>
                navigate(`/organization/${organizationId}/configuration/create`)
              }
            >
              Create Configuration
            </Button>
          </Skeleton>
        </Stack>
      </Box>
      <Skeleton variant="inline" loading={isLoading || isError}>
        <ClientConfigurationsTable
          configurations={configs}
          onConfigPropertyChange={function (
            newValue: ClientConfiguration,
          ): void {
            const oldDirty = dirtyConfigs.configs.filter(
              (c) => c.id !== newValue.id,
            );
            if (newValue.isDefault) {
              oldDirty.forEach((c) => (c.isDefault = false));
            }
            const newDirty = [...oldDirty, newValue];
            setDirtyConfigs({
              configs: newDirty,
              isFaulty: newDirty.some((c) => !!!c.name),
            });
          }}
          onDeleteConfig={function (configId: string): void {
            setSaveChangesModalOpened(true);
            setDeleteConfigId(configId);
          }}
        />
      </Skeleton>
      <ConfirmSaveChangesModal
        isOpen={saveChangesModalOpened}
        onClose={function (): void {
          setDeleteConfigId(null);
          setSaveChangesModalOpened(false);
        }}
        onSaveChanges={async function (): Promise<void> {
          if (deleteConfigId) {
            await handleConfirmDelete();
            return;
          }
        }}
        message={() => {
          return "Are you sure you want to delete this configuration?";
        }}
      />
    </>
  );
};

export default ClientConfigurationsPage;
