import { useTheme } from "@emotion/react";
import React from "react";
import {
  MigrationStatistics,
  calculateFilesStatistics,
} from "../../models/Statistics/MigrationStatistics";
import StatisticsCard from "../common/StatisticsCard";

interface OverallStatisticsCardProps {
  statistics: MigrationStatistics;
}

const OverallStatisticsCard: React.FC<OverallStatisticsCardProps> = ({
  statistics,
}) => {
  const theme = useTheme() as any;
  const {
    casesStatistics,
    intakesStatistics,
    providersStatistics,
    firmDocumentFoldersStatistics,
    templateFilesStatistics,
  } = statistics;

  const filesStatistics = calculateFilesStatistics(statistics);
  const totalFinishedMigrations =
    casesStatistics.casesMigrated +
    intakesStatistics.intakesMigrated +
    providersStatistics.providersMigrated +
    firmDocumentFoldersStatistics.foldersMigrated +
    templateFilesStatistics.templateLibrariesMigrated;
  const totalMigrationsInProgress =
    casesStatistics.casesInProgress +
    intakesStatistics.intakesInProgress +
    providersStatistics.providersInProgress +
    firmDocumentFoldersStatistics.foldersInProgress +
    templateFilesStatistics.templateLibrariesInProgress;
  const totalFailedMigrations =
    casesStatistics.casesInterrupted +
    intakesStatistics.intakesInterrupted +
    providersStatistics.providersInterrupted +
    firmDocumentFoldersStatistics.foldersInterrupted +
    templateFilesStatistics.templateLibrariesInterrupted;
  const totalNumberOfMigrations =
    casesStatistics.numberOfCases +
    intakesStatistics.numberOfIntakes +
    providersStatistics.numberOfProviders +
    firmDocumentFoldersStatistics.numberOfFolders +
    templateFilesStatistics.numberOfTemplateLibraries;
  const totalResolvedMigrations =
    casesStatistics.casesResolved +
    intakesStatistics.intakesResolved;
  const totalAcknowledgedMigrations =
    casesStatistics.casesAcknowledged +
    intakesStatistics.intakesAcknowledged;
  const totalFlaggedMigrations =
    (casesStatistics.casesFlagged + intakesStatistics.intakesFlagged) -
    (totalResolvedMigrations + totalAcknowledgedMigrations);
  const totalNotStartedMigrations =
    totalNumberOfMigrations - 
    totalFinishedMigrations -
    totalFailedMigrations -
    totalMigrationsInProgress -
    totalFlaggedMigrations -
    totalResolvedMigrations -
    totalAcknowledgedMigrations
  ;
  
  const caseChartData = [
    {
      name: "Migrated",
      value: totalFinishedMigrations,
      color: theme.palette.success.plainColor,
    },
    {
      name: "In Progress",
      value: totalMigrationsInProgress,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: totalFailedMigrations,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value: totalNotStartedMigrations,
      color: theme.palette.text.secondary,
    },
    {
      name: "Flagged",
      value: totalFlaggedMigrations,
      color: theme.palette.primary.plainColor,
    },
    {
      name: "Allowed",
      value: totalResolvedMigrations,
      color: theme.palette.lime.solidBg,
    },
    {
      name: "Acknowledged",
      value: totalAcknowledgedMigrations,
      color: theme.palette.yellow.plainColor,
    },
  ];
  const filesChartData = [
    {
      name: "Migrated",
      value: filesStatistics.TotalFinishedFiles,
      color: theme.palette.success.plainColor,
    },
    {
      name: "Not Found",
      value: filesStatistics.TotalNotFoundFiles,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: filesStatistics.TotalFailedFiles,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value: filesStatistics.TotalNotStartedFiles,
      color: theme.palette.text.secondary,
    },
    {
      name: "Flagged",
      value: filesStatistics.TotalFlaggedFiles,
      color: theme.palette.primary.plainColor,
    },
  ];
  return (
    <StatisticsCard
      pieChartSize={250}
      migrationChartData={{ data: caseChartData, label: "Migrations" }}
      filesChartData={{ data: filesChartData, label: "Files" }}
      title={"Migration Statistics"}
      totalFilesSizeInGb={filesStatistics.TotalFilesSizeInGb}
      totalMigratedFilesSizeInGb={filesStatistics.MigratedFilesSizeInGb}
      totalFilesSizeInMb={filesStatistics.TotalFilesSizeInMb}
      totalMigratedFilesSizeInMb={filesStatistics.MigratedFilesSizeInMb}
    />
  );
};

export default OverallStatisticsCard;
