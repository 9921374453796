import LogoutIcon from "@mui/icons-material/Logout";
import IconButton, { IconButtonProps } from "@mui/joy/IconButton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function LogoutButton({
  onClick,
  sx,
  ...props
}: IconButtonProps) {
  const auth = useAuth();
  const navigate = useNavigate();
  return (
    <IconButton
      id="toggle-mode"
      size="sm"
      variant="outlined"
      color="neutral"
      {...props}
      onClick={(event) => {
        auth.logout();
        navigate("/login");
      }}
    >
      <LogoutIcon />
    </IconButton>
  );
}
