import { FormControl, FormLabel, Option, Select } from "@mui/joy";
import * as React from "react";

interface LoggingVerbositySelectProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

const LoggingVerbositySelect: React.FC<LoggingVerbositySelectProps> = ({
  value,
  onChange,
}) => {
  const handleSelectChange = (selectedValue: string | null) => {
    const isDebugSelected = selectedValue === "Debug";
    onChange(isDebugSelected);
  };

  return (
    <FormControl>
      <FormLabel id="logging-verbosity-label">Logging Verbosity</FormLabel>
      <Select
        id="logging-verbosity"
        name="loggingVerbosity"
        value={value ? "Debug" : "Standard"}
        onChange={(event, value) => handleSelectChange(value)}
      >
        <Option value="Standard">Standard</Option>
        <Option value="Debug">Debug</Option>
      </Select>
    </FormControl>
  );
};

export default LoggingVerbositySelect;
