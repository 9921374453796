import { useTheme } from "@emotion/react";
import React from "react";
import { ProvidersStatistics } from "../../models/Statistics/ProvidersStatistics";
import StatisticsCard from "../common/StatisticsCard";

interface ProvidersStatisticsCardProps {
  statistics: ProvidersStatistics;
}

const ProvidersStatisticsCard: React.FC<ProvidersStatisticsCardProps> = ({
  statistics,
}) => {
  const theme = useTheme() as any;
  const caseChartData = [
    {
      name: "Migrated",
      value: statistics.providersMigrated,
      color: theme.palette.success.plainColor,
    },
    {
      name: "In Progress",
      value: statistics.providersInProgress,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: statistics.providersInterrupted,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value:
        statistics.numberOfProviders -
        (statistics.providersMigrated +
          statistics.providersInterrupted +
          statistics.providersInProgress),
      color: theme.palette.text.secondary,
    },
  ];
  const filesChartData = [
    {
      name: "Migrated",
      value: statistics.filesMigrated,
      color: theme.palette.success.plainColor,
    },
    {
      name: "Not Found",
      value: statistics.filesNotFound,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: statistics.filesInterrupted,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value:
        statistics.totalNumberOfFiles -
        (statistics.filesMigrated +
          statistics.filesInterrupted +
          statistics.filesNotFound),
      color: theme.palette.text.secondary,
    },
  ];
  return (
    <StatisticsCard
      migrationChartData={{ data: caseChartData, label: "Providers" }}
      filesChartData={{ data: filesChartData, label: "Files" }}
      title={"Providers Statistics"}
      totalFilesSizeInGb={statistics.totalFilesSizeInGb}
      totalMigratedFilesSizeInGb={statistics.totalMigratedFilesSizeInGb}
      totalFilesSizeInMb={statistics.totalFilesSizeInMb}
      totalMigratedFilesSizeInMb={statistics.totalMigratedFilesSizeInMb}
    />
  );
};

export default ProvidersStatisticsCard;
