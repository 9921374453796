import { extendTheme, PaletteRange } from '@mui/joy/styles';
import {customColors} from "./CustomColors";

declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    pink: true;
    lime: true;
    yellow: true;
  }

  interface Palette {
    pink: PaletteRange;
    lime: PaletteRange;
    yellow: PaletteRange;
  }
}

const customTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        pink: customColors.pink,
        lime: customColors.lime,
        yellow: customColors.yellow,
      },
    },
    dark: {
      palette: {
        pink: customColors.pink,
        lime: customColors.lime,
        yellow: customColors.yellow,
      },
    },
  },
  components: {
    JoyChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.radius.sm,
        }),
      },
    },
  },
});

export default customTheme;
