/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { IconButton, Skeleton } from "@mui/joy";
import Fuse from "fuse.js";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Order, compareBy, stableSort } from "../../helpers/TableHelpers";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";
import SearchIcon from "@mui/icons-material/Search";
import { Delete } from "@mui/icons-material";
import UsersRepository from "../../repositories/UsersRepository";
import { User } from "../../models/User";

interface UsersTableProps {
  onDeleteUser: (configId: string) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({ onDeleteUser }) => {
  const [order, setOrder] = React.useState<Order>("desc");
  const [sort, setSort] = React.useState<string>("id");
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState<User[]>([]);

  const navigate = useNavigate();
  const usersRepository = new UsersRepository();

  const usersQuery = useQuery(
    "users",
    async () => {
      return await usersRepository.getUsers();
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1,
      refetchIntervalInBackground: true,
      refetchOnMount: 'always',
    }
  );

  useEffect(() => {
    if (usersQuery.data) {
      setUsers(usersQuery.data.data);
    }
  }, [usersQuery.data]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSort("");
    setSearchQuery(event.target.value);
  };

  const options = {
    keys: ["id", "username", "email"],
    includeScore: true,
    threshold: 0.5,
    isCaseSensitive: true,
  };

  const { isError, isFetching } = usersQuery;
  let filteredUsers = [] as User[];
  const fuse = new Fuse(users, options);
  const searchResults = fuse.search(searchQuery);

  filteredUsers = searchQuery
    ? searchResults.map((result) => result.item)
    : users;

  const sortedResults = sort
    ? stableSort<User>(filteredUsers, compareBy(order, sort))
    : filteredUsers;

  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <FormControl sx={{ flex: 1 }} size="sm">
            <FormLabel>Search for users</FormLabel>
            <Input
              size="sm"
              placeholder="Search"
              startDecorator={<SearchIcon />}
              onChange={handleSearchChange}
            />
          </FormControl>
        </Skeleton>
      </Box>
      <Sheet
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "2%",
                    padding: "12px 6px",
                  }}
                />
                <th
                  style={{
                    width: "10%",
                    padding: "12px 6px",
                  }}
                >
                  <SortableTableColumnHeader
                    label={"Id"}
                    sortProperty={"id"}
                    getSort={() => sort}
                    setSort={(newSort) => setSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => setOrder(newOrder)}
                  />
                </th>
                <th style={{ width: "10%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Username"}
                    sortProperty={"username"}
                    getSort={() => sort}
                    setSort={(newSort) => setSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => setOrder(newOrder)}
                  />
                </th>
                <th style={{ width: "10%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Email"}
                    sortProperty={"email"}
                    getSort={() => sort}
                    setSort={(newSort) => setSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => setOrder(newOrder)}
                  />
                </th>
                <th
                  style={{
                    width: "5%",
                    minWidth: 140,
                    padding: "12px 6px",
                  }}
                />
              </tr>
            </thead>
            <tbody>
              {sortedResults.map((user) => {
                return (
                  <tr
                    key={user.id}
                    onDoubleClick={() => navigate(`/users/${user.id}`)}
                  >
                    <td style={{ textAlign: "center", width: 60 }}></td>
                    <td>
                      <Typography
                        level="body-sm"
                        sx={{ wordBreak: "break-word" }}
                      >
                        {user.id}
                      </Typography>
                    </td>
                    <td>
                      <Typography
                        level="body-md"
                        sx={{ wordBreak: "break-word" }}
                      >
                        {user.username}
                      </Typography>
                    </td>
                    <td>
                      <Typography
                        level="body-md"
                        sx={{ wordBreak: "break-word" }}
                      >
                        {user.email}
                      </Typography>
                    </td>
                    <td>
                      <IconButton
                        aria-label="Delete"
                        size="sm"
                        color="danger"
                        onClick={() => onDeleteUser(user.id ?? "")}
                      >
                        <Delete />
                      </IconButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Skeleton>
      </Sheet>
    </React.Fragment>
  );
};

export default UsersTable;
