import * as React from "react";

import FileExtensionsInput from "../common/FileExtensionsInput";

interface FileExtensionsFlagInputProps {
  extensionFlags: string[];
  onChange: (value: string[]) => void;
}
const FileExtensionsFlagInput: React.FC<FileExtensionsFlagInputProps> = ({
    extensionFlags,
  onChange,
}) => {
  return (
    <FileExtensionsInput 
      inputLabel="File Extension Flags"
      extensions={extensionFlags}
      onExtensionsChange={onChange}
      />
  );
};

export default FileExtensionsFlagInput;
