import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Input,
  Sheet,
  Skeleton,
  Stack,
  Typography,
} from "@mui/joy";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ColorSchemeToggle from "../components/common/ColorSchemeToggle";
import { useAuth } from "../contexts/AuthContext";

const LoginPage: React.FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      logIn();
    }
  };
  const apiUrl = process.env.REACT_APP_API_URL ?? '/';

  const logIn = async () => {
    try {
      setIsLoggingIn(true);
      const response = await axios.post(`${apiUrl}api/signin`, {
            username,
            password,
      });
      const { accessToken, refreshToken } = response.data;
      login(accessToken, refreshToken);
      navigate("/organizations");
    } catch (error) {
      alert("Invalid username or password");
    }
    setIsLoggingIn(false);
  };

  return (
    <Sheet
      sx={{
        alignSelf: "center",
        height: "50%",
        width: "25%",

        mx: "auto", // margin left & right
        my: 4, // margin top & bottom
        py: 3, // padding top & bottom
        px: 2, // padding left & right
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRadius: "sm",
        boxShadow: "md",
      }}
      variant="outlined"
    >
      <Card>
        <CardContent>
          <Stack height={"100%"} direction={"row"}>
            <Typography level="h4" component="h1">
              <b>Neos Files Sync</b>
            </Typography>
            <ColorSchemeToggle
              sx={{ ml: "auto", display: { xs: "none", md: "inline-flex" } }}
            />
          </Stack>
          <FormControl>
            <FormLabel>Username</FormLabel>
            <Input
              type="text"
              value={username}
              onChange={handleUsernameChange}
              onKeyDown={handleKeyPress}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input
              onKeyDown={handleKeyPress}
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </FormControl>
          <Skeleton variant='inline' loading={isLoggingIn}>
          <Button onClick={() => logIn()} type="submit" sx={{ mt: 1 }}>
            Log in
          </Button>
          </Skeleton>
        </CardContent>
      </Card>
    </Sheet>
  );
};

export default LoginPage;
