import { Box, Typography } from "@mui/joy";
import { useParams } from "react-router-dom";
import CasesTable from "../components/Cases/CasesTable";

const CasesPage = () => {
    const { organizationId } = useParams();
    return (
    <>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Cases</Typography>      
      </Box>
      <CasesTable organizationId={organizationId ?? ""} />     
    </>
  );
};

export default CasesPage;
