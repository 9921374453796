import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {Divider, Modal, ModalDialog} from "@mui/joy";
import Button from "@mui/joy/Button";
import DialogActions from '@mui/joy/DialogActions';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import React from "react";
import {MigrationFlagStatus} from "../../models/Flags/MigrationFlagStatus";

interface UpdateCaseFlagModalProps {
  open: boolean;
  caseNumber: string;
  flagStatus: MigrationFlagStatus;
  onClose: () => void;
  onConfirm: () => void;
}

const UpdateCaseFlagModal: React.FC<UpdateCaseFlagModalProps> = ({
  open,
  caseNumber,
  flagStatus,
  onClose,
  onConfirm,
}) => {

  const getMessage = () => {
    switch (flagStatus) {
      case MigrationFlagStatus.UnResolved:
        return `Are you sure you want to block case ${caseNumber} migration?`;
      case MigrationFlagStatus.Resolved:
        return `Are you sure you want to allow case ${caseNumber} migration?`;
      case MigrationFlagStatus.Acknowledged:
        return `Are you sure you want to to acknowledge the flag on ${caseNumber} migration?`;
      default:
        return '';
    }
  };

  const getStatusColor = () => {
    switch (flagStatus) {
      case MigrationFlagStatus.UnResolved:
        return 'danger';
      case MigrationFlagStatus.Resolved:
        return 'lime';
      case MigrationFlagStatus.Acknowledged:
        return 'yellow';
      default:
        return 'neutral';
    }
  };
  
  const getButtonText = () => {
    switch (flagStatus) {
      case MigrationFlagStatus.UnResolved:
        return 'Block';
      case MigrationFlagStatus.Resolved:
        return 'Allow';
      case MigrationFlagStatus.Acknowledged:
        return 'Acknowledge';
      default:
        return 'neutral';
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Confirmation required
          </DialogTitle>
          <Divider />
          <DialogContent>
            {getMessage()}
          </DialogContent>
          <DialogActions>
            <Button 
              variant="solid"
              onClick={() => onConfirm()} 
              color={getStatusColor()}
            >
              {getButtonText()}
            </Button>
            <Button 
              variant="plain"
              color="neutral"
              onClick={() => onClose()}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
  );
};

export default UpdateCaseFlagModal;
