import axios from 'axios';
import React, { useState, useEffect } from 'react';

interface OrganizationInfo {
    id: string;
    name: string;
    intactId: string;
}

const AdminConsentPage = () => {
    const [tenantName, setTenantName] = useState('');
    const [intactId, setIntactId] = useState('');
    const [bindToOrganization, setBindToOrganization] = useState(false);
    const [organizationLoaded, setOrganizationLoaded] = useState(false);
    useEffect(() => {
        document.title = 'Grant admin consent for MS Graph - Assembly Software';
        let favicon = document.querySelector('link[rel="icon"]');
        if (!favicon) {
           favicon = document.createElement('link');
           (favicon as HTMLLinkElement).rel = 'icon';
           document.head.appendChild(favicon);
        }
        if (favicon instanceof HTMLLinkElement) {
           favicon.href = '/adminconsent/images/icons/favicon.png';
        }
        const styleSheet = document.createElement('link');
        styleSheet.href = '/adminconsent/css/main.css';
        styleSheet.rel = 'stylesheet';
        const font = document.createElement('link');
        font.href = '/adminconsent/fonts/font-awesome-4.7.0/css/font-awesome.min.css';
        font.rel = 'stylesheet';
        document.head.appendChild(styleSheet);
        document.head.appendChild(font);

        const fetchData = async () => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const organizationId = urlParams.get('organizationId');
            if (organizationId && !organizationLoaded) {
                const apiUrl = process.env.REACT_APP_API_URL ?? "/";
                const api = axios.create({
                    baseURL: apiUrl,
                    timeout: 30000,
                });
                try {
                    const response = await api.get(`/api/adminconsent?organizationId=${organizationId}`);
                    if (response.data) {
                        const organization: OrganizationInfo = response.data;
                        if(organization){
                            setTenantName(organization.name);
                            setIntactId(organization.intactId);
                            setBindToOrganization(true);
                            setOrganizationLoaded(true);
                        }
                    } 
                } catch (error) {
                    console.error('Error fetching organization info:', error);
                }
            }
        };
        fetchData();

        return () => {
          document.head.removeChild(styleSheet);
          document.head.removeChild(font);
        };
      }, [organizationLoaded]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if(tenantName.length === 0 || (bindToOrganization && intactId.length === 0)) return;
        const azureAppClientId = "ca64f31f-2781-418c-9f74-7eae4c9b4665";
        const consentUrl = `https://login.microsoftonline.com/common/adminconsent?client_id=${azureAppClientId}&state=${JSON.stringify({
            tenantName,
            bindToOrganization,
            intactId
        })}&redirect_uri=https://${window.location.host}/adminconsent/result`;
        window.location.href = consentUrl;
    };

    return (
        <div className="limiter">
            <div className="container-login100" style={{backgroundImage: "url('/adminconsent/images/backgrounds/background.png')"}}>
                <div className="wrap-login100">
                    <form className="login100-form validate-form" onSubmit={handleSubmit}>
                        <span className="login100-form-title p-b-48">
                            <img width="250" height="48" src="/adminconsent/images/logos/assembly-logo.webp" alt="Assembly Logo" />
                        </span>
                        <div className={"wrap-input100" + (tenantName.length > 0 ? "" : " alert-validate")} data-validate="Enter tenant name!">
                            <input
                                className="input100 "
                                type="text"
                                placeholder="Tenant name"
                                value={tenantName}
                                onChange={(e) => setTenantName(e.target.value)}
                            />
                            <span className="focus-input100"></span>
                        </div>
                        <div hidden={!bindToOrganization} className={"wrap-input100" + (intactId.length > 0 ? "" : " alert-validate")} data-validate="Enter intact id of organization!">
                            <input
                                className="input100"
                                type="text"
                                placeholder="Organization intact id"
                                value={intactId}
                                onChange={(e) => setIntactId(e.target.value)}
                            />
                            <span className="focus-input100"></span>
                        </div>
                        <div className="wrap-input100 checkbox">
                            <input
                                id="bind-organization"
                                className="input-checkbox"
                                type="checkbox"
                                checked={bindToOrganization}
                                onChange={(e) => setBindToOrganization(e.target.checked)}
                            />
                            <label htmlFor="bind-organization">Bind tenant to organization</label>
                        </div>
                        <div className="container-login100-form-btn">
                            <div className="wrap-login100-form-btn">
                                <div className="login100-form-bgbtn"></div>
                                <button className="login100-form-btn" type="submit">
                                    Grant admin consent
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AdminConsentPage;
