import { Button, Stack, ToggleButtonGroup } from "@mui/joy";
import React from "react";
import {
  CaseMigrationStrategy,
  CaseMigrationStrategyLabels,
} from "../../models/ClientConfiguration/CaseMigrationStrategy";

interface CaseMigrationStrategySelectProps {
  value: CaseMigrationStrategy[];
  onChange: (value: CaseMigrationStrategy[]) => void;
  required: boolean;
}

const CaseMigrationStrategySelect: React.FC<
  CaseMigrationStrategySelectProps
> = ({ value, onChange, required }) => {
  const handleCaseToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    selectedValue: CaseMigrationStrategy,
  ) => {
    const newValues: CaseMigrationStrategy[] = [
      ...value.filter(
        (val) =>
          ![
            CaseMigrationStrategy.AllCases,
            CaseMigrationStrategy.Custom,
            CaseMigrationStrategy.BasicPlus,
          ].includes(val),
      ),
    ];
    newValues.push(selectedValue);
    onChange(newValues);
  };

  const handleDateToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    selectedValue: CaseMigrationStrategy,
  ) => {
    const newValues: CaseMigrationStrategy[] = [
      ...value.filter(
        (val) =>
          ![
            CaseMigrationStrategy.DateAscending,
            CaseMigrationStrategy.DateDescending,
          ].includes(val),
      ),
    ];
    newValues.push(selectedValue);
    onChange(newValues);
  };

  return (
    <Stack spacing={1} sx={{ py: 1 }}>
      <ToggleButtonGroup
        sx={{ width: "100%" }}
        value={
          value
            .filter((val) =>
              [
                CaseMigrationStrategy.AllCases,
                CaseMigrationStrategy.Custom,
                CaseMigrationStrategy.BasicPlus,
              ].includes(val),
            )
            .at(0) || [CaseMigrationStrategy.AllCases]
        }
        onChange={(event, newValue) => {
          handleCaseToggleChange(event, newValue as CaseMigrationStrategy);
        }}
      >
        <Button fullWidth value={CaseMigrationStrategy.AllCases}>
          {CaseMigrationStrategyLabels[CaseMigrationStrategy.AllCases]}
        </Button>
        <Button fullWidth value={CaseMigrationStrategy.Custom}>
          {CaseMigrationStrategyLabels[CaseMigrationStrategy.Custom]}
        </Button>
        <Button fullWidth value={CaseMigrationStrategy.BasicPlus}>
          {CaseMigrationStrategyLabels[CaseMigrationStrategy.BasicPlus]}
        </Button>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        sx={{ width: "100%" }}
        value={value
          .filter((val) =>
            [
              CaseMigrationStrategy.DateAscending,
              CaseMigrationStrategy.DateDescending,
            ].includes(val),
          )
          .at(0)}
        onChange={(event, newValue) => {
          handleDateToggleChange(event, newValue as CaseMigrationStrategy);
        }}
      >
        <Button fullWidth value={CaseMigrationStrategy.DateAscending}>
          {CaseMigrationStrategyLabels[CaseMigrationStrategy.DateAscending]}
        </Button>
        <Button fullWidth value={CaseMigrationStrategy.DateDescending}>
          {CaseMigrationStrategyLabels[CaseMigrationStrategy.DateDescending]}
        </Button>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default CaseMigrationStrategySelect;
