export enum MigrationType {
  CasesMigration = "casesMigration",
  CaseFoldersMigration = "caseFoldersMigration",
  ProvidersMigration = "providersMigration",
  FirmDocumentsMigration = "firmDocumentsMigration",
  TemplatesMigration = "templatesMigration",
  IntakesMigration = "intakesMigration",
}

export const MigrationTypeLabels: Record<MigrationType, string> = {
  [MigrationType.CasesMigration]: "Cases",
  [MigrationType.CaseFoldersMigration]: "Case Folders",
  [MigrationType.ProvidersMigration]: "Providers",
  [MigrationType.FirmDocumentsMigration]: "Firm Documents",
  [MigrationType.TemplatesMigration]: "Templates",
  [MigrationType.IntakesMigration]: "Intakes",
};