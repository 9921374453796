import { FormControl, FormLabel, Grid, Input } from "@mui/joy";
import React from "react";
import { AzureBlobConfiguration } from "../../models/ClientConfiguration/AzureBlobConfiguration";

interface AzureBlobSectionProps {
  azureBlobConfig: AzureBlobConfiguration | undefined;
  onChange: (updatedConfig: AzureBlobConfiguration) => void;
}

const AzureBlobSection: React.FC<AzureBlobSectionProps> = ({
  azureBlobConfig,
  onChange,
}) => {
  const handleFieldChange = (
    field: keyof AzureBlobConfiguration,
    value: string,
  ) => {
    onChange({
      ...azureBlobConfig!,
      [field]: value,
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <FormControl>
          <FormLabel>Connection String</FormLabel>
          <Input
            required
            fullWidth
            size="sm"
            value={azureBlobConfig?.connectionString || ""}
            onChange={(e) =>
              handleFieldChange("connectionString", e.target.value)
            }
          />
        </FormControl>
      </Grid>
      <Grid xs={12} md={6}>
        <FormControl>
          <FormLabel>Blob Name</FormLabel>
          <Input
            required
            fullWidth
            value={azureBlobConfig?.blobName || ""}
            onChange={(e) => handleFieldChange("blobName", e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid xs={12} md={6}>
        <FormControl>
          <FormLabel>CSV Configuration Path</FormLabel>
          <Input
            required
            fullWidth
            value={azureBlobConfig?.csvConfigurationPath || ""}
            onChange={(e) =>
              handleFieldChange("csvConfigurationPath", e.target.value)
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default AzureBlobSection;
