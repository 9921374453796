import ReorderIcon from "@mui/icons-material/Reorder";
import SortIcon from "@mui/icons-material/Sort";
import { Link } from "@mui/joy";
import React from "react";
import { Order } from "../../helpers/TableHelpers";

interface SortableTableColumnHeaderProps {
  label: string;
  sortProperty: string;
  getSort: () => string;
  setSort: (sort: string) => void;
  getOrder: () => Order;
  setOrder: (order: Order) => void;
}

const SortableTableColumnHeader: React.FC<SortableTableColumnHeaderProps> = ({ label, sortProperty, getSort, setSort, getOrder, setOrder }) => {

  return (
    <Link
    underline="none"
    component="button"
    onClick={() => {
      if(sortProperty === getSort()){
        setOrder(getOrder() === "asc" ? "desc" : "asc")
      }
      else{
        setSort(sortProperty);
      }
      
    }}
    fontWeight="lg"
    endDecorator={
      getSort() === sortProperty ? <SortIcon/> : <ReorderIcon />
    }
    sx={{
      "& svg": {
        transition: "0.2s",
        transform:
          getOrder() === "asc" && getSort() === sortProperty
            ? "rotate(180deg) scaleX(-1)"
            : "rotate(0deg)",
      },
      color: getSort() === sortProperty ? "primary" : "text.primary",
    }}
  >
    {label}
  </Link>
  );
};

export default SortableTableColumnHeader;
