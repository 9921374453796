import React, { createContext, useContext, useState, ReactNode } from 'react';

interface OrganizationContextProps {
  selectedOrganizationId: string | null;
  setSelectedOrganizationId: (id: string | null) => void;
}

const OrganizationContext = createContext<OrganizationContextProps | undefined>(undefined);

export const OrganizationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | null>(null);

  return (
    <OrganizationContext.Provider value={{ selectedOrganizationId, setSelectedOrganizationId }}>
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = (): OrganizationContextProps => {
  const context = useContext(OrganizationContext);
  if (!context) {
    throw new Error('useOrganization must be used within an OrganizationProvider');
  }
  
  return context;
};