import React, { FC, useEffect, useState, ReactNode } from 'react';
import Autocomplete from '@mui/joy/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import ListItemContent from '@mui/joy/ListItemContent';
import CircularProgress from '@mui/joy/CircularProgress';

interface AutocompleteOption {
  id: string;
  label: string;
}

interface GenericAutocompleteProps<T extends AutocompleteOption> {
  options: T[];
  inputValue: T;
  setValue: (value: T) => void;
  placeholder: string;
  width?: string;
  size?: "lg" | "sm" | "md";
  startDecorator?: ReactNode;
}

const GenericAutocomplete: FC<GenericAutocompleteProps<AutocompleteOption>> = ({
  options,
  inputValue,
  setValue,
  placeholder,
  width = '100%',
  size,
  startDecorator,
}) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (inputValue) {
      setLoading(false);
    }
  }, [inputValue]);
  
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: AutocompleteOption | null,
  ) => {
    if (value) {
      setValue(value);
    }
  };

  return (
    <Autocomplete
      sx={{ width }}
      size={size || "md"}
      placeholder={placeholder}
      startDecorator={startDecorator}
      loading={loading}
      endDecorator={
        loading ? (
          <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
        ) : null
      }
      disableClearable
      options={options}
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
      value={inputValue}
      renderOption={(props, option) => (
        <AutocompleteOption {...props}>
          <ListItemContent sx={{ fontSize: size || "md" }}>
            {option.label}
          </ListItemContent>
        </AutocompleteOption>
      )}
    />
  );
}

export default GenericAutocomplete;
