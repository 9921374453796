import { useTheme } from "@emotion/react";
import React from "react";
import { CasesStatistics } from "../../models/Statistics/CasesStatistics";
import StatisticsCard from "../common/StatisticsCard";

interface CasesStatisticsCardProps {
  statistics: CasesStatistics;
}

const CasesStatisticsCard: React.FC<CasesStatisticsCardProps> = ({
  statistics,
}) => {
  const theme = useTheme() as any;
  const caseChartData = [
    {
      name: "Migrated",
      value: statistics.casesMigrated,
      color: theme.palette.success.plainColor,
    },
    {
      name: "In Progress",
      value: statistics.casesInProgress,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: statistics.casesInterrupted,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value:
        statistics.numberOfCases -
        (statistics.casesMigrated +
          statistics.casesInterrupted +
          statistics.casesInProgress +
          statistics.casesFlagged
        ),
      color: theme.palette.text.secondary,
    },
    {
      name: "Flagged",
      value: statistics.casesFlagged - (statistics.casesAcknowledged + statistics.casesResolved),
      color: theme.palette.primary.plainColor,
    },
    {
      name: "Allowed",
      value: statistics.casesResolved,
      color: theme.palette.lime.solidBg,
    },
    {
      name: "Acknowledged",
      value: statistics.casesAcknowledged,
      color: theme.palette.yellow.plainColor,
    },
  ];
  const filesChartData = [
    {
      name: "Migrated",
      value: statistics.filesMigrated,
      color: theme.palette.success.plainColor,
    },
    {
      name: "Not Found",
      value: statistics.filesNotFound,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: statistics.filesInterrupted,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value:
        statistics.totalNumberOfFiles -
        (statistics.filesMigrated +
          statistics.filesInterrupted +
          statistics.filesNotFound +
          statistics.filesFlagged),
      color: theme.palette.text.secondary,
    },
    {
      name: "Flagged",
      value: statistics.filesFlagged,
      color: theme.palette.primary.plainColor,
    },
  ];
  const flagsChartData = [
    {
      name: "DICOM",
      value: statistics.dicomFilesFlagged,
      color: theme.palette.primary[300],
    },
    {
      name: "Flagged Extensions",
      value: statistics.msgFilesFlagged,
      color: theme.palette.primary[400],
    },
    {
      name: "Cases >10K",
      value: statistics.filesInCasesWithOver10KFiles,
      color: theme.palette.primary[500],
    },
    {
      name: "Moved To Firm Documents",
      value: statistics.filesMovedToFirmDocuments,
      color: theme.palette.success.plainColor,
    }
  ];
  return (
    <StatisticsCard
      migrationChartData={{ data: caseChartData, label: "Cases" }}
      filesChartData={{ data: filesChartData, label: "Files" }}
      fileFlagsChartData={{ data: flagsChartData, label: "Flags"}}
      title={"Cases Statistics"}
      totalFilesSizeInGb={statistics.totalFilesSizeInGb}
      totalMigratedFilesSizeInGb={statistics.totalMigratedFilesSizeInGb}
      totalFilesSizeInMb={statistics.totalFilesSizeInMb}
      totalMigratedFilesSizeInMb={statistics.totalMigratedFilesSizeInMb}
      totalFilesMovedToFirmDocsSizeInGb={statistics.filesMovedToFirmDocumentsSizeInGb}
      totalFilesMovedToFirmDocsSizeInMb={statistics.filesMovedToFirmDocumentsSizeInMb}
      notResolvedFlaggedFilesSizeInGb={statistics.notResolvedFlaggedFilesSizeInGb}
      notResolvedFlaggedFilesSizeInMb={statistics.notResolvedFlaggedFilesSizeInMb}
    />
  );
};

export default CasesStatisticsCard;
