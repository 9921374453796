import Delete from "@mui/icons-material/Delete";
import { Box, Grid, IconButton, Input, ListItem } from "@mui/joy";
import * as React from "react";
import { RootCaseFolder } from "../../models/ClientConfiguration/RootCaseFolder";
import AddableList from "../common/AddableList";

interface RootCaseFoldersConfigurationControlProps {
  folders: RootCaseFolder[];
  onChange: (folders: RootCaseFolder[]) => void;
}

const RootCaseFoldersConfigurationControl: React.FC<
  RootCaseFoldersConfigurationControlProps
> = ({ folders, onChange }) => {
  const handleAddClick = () => {
    const newFolder: RootCaseFolder = {
      folderPath: "",
    };
    const updatedFolders = [...folders, newFolder];
    onChange(updatedFolders);
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const handleAddFolder = (index: number, value: string) => {
    let trimmedValue = value.trim();
    const updatedFolders = [...folders];
    if (trimmedValue) {
      trimmedValue = trimmedValue.replace("/", "\\");
    }
    updatedFolders[index].folderPath = trimmedValue;
    onChange(updatedFolders);
  };
  const removeDuplicates = () => {
    const uniqueFolders = Array.from(new Set(folders.map((f) => f.folderPath)));
    onChange(
      uniqueFolders.map((f) => {
        const folder: RootCaseFolder = {
          folderPath: f,
        };
        return folder;
      }),
    );
  };
  const handleDeleteFolder = (index: number) => {
    const updatedFolders = [...folders];
    updatedFolders.splice(index, 1);
    onChange(updatedFolders);
  };

  return (
    <Box>
      <AddableList
        sx={{ overflow: "auto", maxHeight: "25vh" }}
        onAddClick={handleAddClick}
        subheaderText="Root Case Folders"
      >
        {folders.map((folder, index) => (
          <ListItem
            endAction={
              <IconButton
                aria-label="Delete"
                size="sm"
                color="danger"
                onClick={() => handleDeleteFolder(index)}
              >
                <Delete />
              </IconButton>
            }
            key={index}
          >
            <Grid
              container
              spacing={1}
              width={"100%"}
              sx={{ paddingRight: "20px" }}
            >
              <Grid xs={12}>
                <Input
                  fullWidth
                  size="sm"
                  placeholder="Folder Path"
                  value={folder.folderPath ?? ""}
                  onBlur={removeDuplicates}
                  onChange={(event) =>
                    handleAddFolder(index, event.target.value)
                  }
                  onKeyDown={(event) =>
                    handleKeyPress(
                      event as React.KeyboardEvent<HTMLInputElement>,
                      index,
                    )
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </AddableList>
    </Box>
  );
};

export default RootCaseFoldersConfigurationControl;
