export enum ImplementationType {
  AzureBlob = "AzureBlob",
  Needles4 = "Needles4",
  Needles5SQL = "Needles5SQL",
  Needles5Sybase = "Needles5Sybase",
  TrialWorks = "TrialWorks",
}

export function getImplementationTypeName(
  implementationType?: ImplementationType | null,
): string {
  switch (implementationType) {
    case ImplementationType.AzureBlob:
      return "Azure Blob";
    case ImplementationType.Needles4:
      return "Needles 4";
    case ImplementationType.Needles5SQL:
      return "Needles 5 - SQL";
    case ImplementationType.Needles5Sybase:
      return "Needles 5 - Sybase";
    case ImplementationType.TrialWorks:
      return "TrialWorks";
    default:
      return "Unknown";
  }
}
