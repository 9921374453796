import AddIcon from "@mui/icons-material/Add";
import { Box, FormLabel, IconButton, List, ListSubheader, Typography, styled } from "@mui/joy";
import React from "react";

const StyledListSubheader = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  zIndex: 2,
  borderRadius: theme.radius.sm,
}));

interface AddableListProps {
  subheaderText: string;
  onAddClick: () => void;
  children: React.ReactNode;
  sx?: React.CSSProperties;
}

const AddableList: React.FC<AddableListProps> = ({
  subheaderText,
  onAddClick,
  children,
  sx,
}) => {
  return (
    <List sx={sx}>
      <ListSubheader sx={{padding: '6px 0px'}}sticky>
        <StyledListSubheader>
          <FormLabel sx={{ padding: "2px 16px", textTransform: "none" }}>
            <Typography level="h4" sx={{ fontSize: "1rem" }}>
              {subheaderText}
            </Typography>
          </FormLabel>
          <IconButton sx={{ marginLeft: "auto" }} onClick={onAddClick}>
            <AddIcon />
          </IconButton>
        </StyledListSubheader>
      </ListSubheader>
      {children}
    </List>
  );
};

export default AddableList;
