import Delete from "@mui/icons-material/Delete";
import { Box, Grid, IconButton, Input, ListItem } from "@mui/joy";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as React from "react";
import { RuntimeScheduleConfiguration } from "../../models/ClientConfiguration/RuntimeScheduleConfiguration";
import AddableList from "../common/AddableList";
import RuntimeScheduleTypeSelect from "./RuntimeScheduleTypeSelect";

interface RuntimeScheduleControlProps {
  runtimeSchedules: RuntimeScheduleConfiguration[];
  onChange: (runtimeSchedules: RuntimeScheduleConfiguration[]) => void;
}
const RuntimeScheduleControl: React.FC<RuntimeScheduleControlProps> = ({
  runtimeSchedules,
  onChange,
}) => {
  const handleAddSchedule = () => {
    const newSchedule: RuntimeScheduleConfiguration = {
      startLocalTime: "",
      endLocalTime: "",
      scheduleType: 1,
    };
    const updatedDriveMappings = [...runtimeSchedules, newSchedule];
    onChange(updatedDriveMappings);
  };

  const handleDeleteSchedule = (index: number) => {
    const updatedSchedules = [...runtimeSchedules];
    updatedSchedules.splice(index, 1);
    onChange(updatedSchedules);
  };

  const handleStartTimeChange = (time: Date | null, index: number) => {
    const updatedSchedules = [...runtimeSchedules];
    const schedule = updatedSchedules[index];
    const endTime = convertTimespanToDate(schedule.endLocalTime);
    if (time && endTime && time > endTime) {
      updatedSchedules[index].endLocalTime = convertDateToTimespan(time);
      updatedSchedules[index].startLocalTime = convertDateToTimespan(endTime);
    } else {
      updatedSchedules[index].startLocalTime = convertDateToTimespan(time);
    }
    onChange(updatedSchedules);
  };
  const handleEndTimeChange = (time: Date | null, index: number) => {
    const updatedSchedules = [...runtimeSchedules];
    const schedule = updatedSchedules[index];
    const startTime = convertTimespanToDate(schedule.startLocalTime);
    if (time && startTime && time < startTime) {
      updatedSchedules[index].startLocalTime = convertDateToTimespan(time);
      updatedSchedules[index].endLocalTime = convertDateToTimespan(startTime);
    } else {
      updatedSchedules[index].endLocalTime = convertDateToTimespan(time);
    }
    onChange(updatedSchedules);
  };
  const convertDateToTimespan = (time: Date | null): string => {
    if (time) {
      const hours = time.getHours().toString().padStart(2, "0");
      const minutes = time.getMinutes().toString().padStart(2, "0");
      const seconds = time.getSeconds().toString().padStart(2, "0");

      const timeSpan = `${hours}:${minutes}:${seconds}`;
      return timeSpan;
    } else {
      return "";
    }
  };
  const convertTimespanToDate = (timespan: string): Date | null => {
    const [hours, minutes] = timespan
      .split(":")
      .map((part) => parseInt(part, 10));

    if (isNaN(hours) || isNaN(minutes)) {
      return null;
    }

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    return date;
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <AddableList
          sx={{ overflow: "auto", maxHeight: "25vh" }}
          onAddClick={handleAddSchedule}
          subheaderText="Runtime Schedules"
        >
          {runtimeSchedules.map((schedule, index) => (
            <ListItem
              sx={{ width: "100%" }}
              key={index}
              endAction={
                <IconButton
                  aria-label="Delete"
                  size="sm"
                  color="danger"
                  onClick={() => handleDeleteSchedule(index)}
                >
                  <Delete />
                </IconButton>
              }
            >
              <Grid
                container
                spacing={1}
                width={"100%"}
                sx={{ paddingRight: "20px" }}
              >
                <Grid xs={2}>
                  <Input
                    fullWidth
                    size={"sm"}
                    type={"time"}
                    placeholder={"Start local time"}
                    value={schedule.startLocalTime}
                    onChange={(event) => {
                      const val = event.target.value;
                      handleStartTimeChange(convertTimespanToDate(val), index);
                    }}
                  />
                </Grid>
                <Grid xs={2}>
                  <Input
                    size={"sm"}
                    fullWidth
                    type={"time"}
                    placeholder="End local time"
                    value={schedule.endLocalTime}
                    onChange={(event) => {
                      const val = event.target.value;
                      handleEndTimeChange(convertTimespanToDate(val), index);
                    }}
                  />
                </Grid>
                <Grid xs={8}>
                  <RuntimeScheduleTypeSelect
                    value={schedule.scheduleType}
                    onChange={(selectedTypes) => {
                      const updatedSchedules = [...runtimeSchedules];
                      updatedSchedules[index].scheduleType = selectedTypes;
                      onChange(updatedSchedules);
                    }}
                  />
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </AddableList>
      </Box>
    </LocalizationProvider>
  );
};

export default RuntimeScheduleControl;
