import { Box, FormControl, FormLabel, Grid, Input } from "@mui/joy";
import React from "react";
import { ClientConfiguration } from "../../models/ClientConfiguration/ClientConfiguration";

interface SharePointSectionProps {
  value: ClientConfiguration;
  onChange: (updatedConfig: ClientConfiguration) => void;
}

const SharePointSection: React.FC<SharePointSectionProps> = ({
  value,
  onChange,
}) => {
  const handleSharePointClientIdChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange({
      ...value,
      sharepointClientId: event.target.value,
    });
  };

  const handleSharePointTenantIdChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange({
      ...value,
      sharepointTenantId: event.target.value,
    });
  };

  const handleSharePointClientSecretChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange({
      ...value,
      sharepointClientSecret: event.target.value,
    });
  };
  const handleSharePointRootSiteNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange({
      ...value,
      sharepointRootSiteName: event.target.value,
    });
  };

  const handleCasesSharePointRootDriveNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange({
      ...value,
      casesSharepointRootDriveName: event.target.value,
    });
  };

  const handleProvidersSharePointRootDriveNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange({
      ...value,
      providersSharepointRootDriveName: event.target.value,
    });
  };

  const handleFirmDocumentsSharePointRootDriveNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange({
      ...value,
      firmDocumentsSharepointRootDriveName: event.target.value,
    });
  };

  const handleTemplateFilesSharePointRootDriveNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange({
      ...value,
      templateFilesSharepointRootDriveName: event.target.value,
    });
  };
  return (
    <Box>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid xs={12} md={6}>
          <FormControl>
            <FormLabel>Sharepoint Client ID</FormLabel>
            <Input
              onChange={handleSharePointClientIdChange}
              required
              value={value.sharepointClientId || ""}
              name="sharepointClientId"
              fullWidth
            />
          </FormControl>
        </Grid>       
        <Grid xs={12} md={6}>
          <FormControl>
            <FormLabel>Sharepoint Client Secret</FormLabel>
            <Input
              onChange={handleSharePointClientSecretChange}
              value={value.sharepointClientSecret || ""}
              name="sharepointClientSecret"
              fullWidth
              required
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={6}>
          <FormControl>
            <FormLabel>Sharepoint Tenant ID</FormLabel>
            <Input
              onChange={handleSharePointTenantIdChange}
              value={value.sharepointTenantId || ""}
              name="sharepointTenantId"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={6}>
          <FormControl>
            <FormLabel>SharePoint Site Name</FormLabel>
            <Input
              onChange={handleSharePointRootSiteNameChange}
              value={value.sharepointRootSiteName || "CloudDocs"}
              fullWidth
              required
            />
          </FormControl>
        </Grid>

        <Grid xs={12} md={3}>
          <FormControl>
            <FormLabel>Cases Root Drive Name</FormLabel>
            <Input
              onChange={handleCasesSharePointRootDriveNameChange}
              value={value.casesSharepointRootDriveName || ""}
              fullWidth
              required
            />
          </FormControl>
        </Grid>

        <Grid xs={12} md={3}>
          <FormControl>
            <FormLabel>Providers Root Drive Name</FormLabel>
            <Input
              onChange={handleProvidersSharePointRootDriveNameChange}
              value={value.providersSharepointRootDriveName || ""}
              fullWidth
              required
            />
          </FormControl>
        </Grid>

        <Grid xs={12} md={3}>
          <FormControl>
            <FormLabel>Firm Documents Root Drive Name</FormLabel>
            <Input
              onChange={handleFirmDocumentsSharePointRootDriveNameChange}
              value={value.firmDocumentsSharepointRootDriveName || ""}
              fullWidth
              required
            />
          </FormControl>
        </Grid>

        <Grid xs={12} md={3}>
          <FormControl>
            <FormLabel>Template Files Root Drive Name</FormLabel>
            <Input
              onChange={handleTemplateFilesSharePointRootDriveNameChange}
              value={value.templateFilesSharepointRootDriveName || ""}
              fullWidth
              required
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SharePointSection;
