import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Input,
  Skeleton,
  Typography,
} from "@mui/joy";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { ClientConfiguration } from "../../models/ClientConfiguration/ClientConfiguration";
import { ImplementationType } from "../../models/ClientConfiguration/ImplementationType";
import ClientConfigurationsRepository from "../../repositories/ClientConfigurationsRepository";
import AzureBlobSection from "./AzureBlobSection";
import ClientSettingsSection from "./ClientSettingsSection";
import MigrationConfigurationSection from "./MigrationConfigurationSection";
import SharePointSection from "./SharePointSection";

interface ClientConfigurationFormProps {
  configuration: ClientConfiguration;
}

const ClientConfigurationForm: React.FC<ClientConfigurationFormProps> = ({
  configuration,
}) => {
  const [config, setConfig] = React.useState(configuration);
  const [isUploading, setUploading] = React.useState(false);
  const configurationsRepository = new ClientConfigurationsRepository();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const configMutation = useMutation(
    ["clientConfigurations", configuration.organizationId],
    async (updatedConfig: ClientConfiguration) => {
      if (config.id) {
        return await configurationsRepository.updateClientConfiguration(
          updatedConfig.id || "",
          updatedConfig,
        );
      } else {
        if (!updatedConfig.dbConnectionString) {
          updatedConfig.dbConnectionString = " ";
        }
        if (!updatedConfig.sharepointTenantId) {
          updatedConfig.sharepointTenantId = " ";
        }
        const newConfig =
          await configurationsRepository.createClientConfiguration(
            updatedConfig,
          );
        navigate(
          `/organization/${newConfig.organizationId}/configuration/${newConfig.id}`,
        );
        return newConfig;
      }
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("clientConfigurations");
      },
      onSettled: () => {
        setUploading(false);
      },
    },
  );

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Skeleton variant="inline" loading={isUploading}>
          <Typography level="h2">
            <Input
              onChange={(event) =>
                setConfig({
                  ...config,
                  name: event.target.value,
                })
              }
              fullWidth
              sx={{ fontSize: "2rem" }}
              size="lg"
              variant="plain"
              value={config.name ?? ""}
              required
              placeholder="Configuration Name"
            />
          </Typography>
        </Skeleton>
        <Skeleton variant="inline" loading={isUploading}>
          <Button
            color="primary"
            onClick={() => {
              configMutation.mutate(config);
              setUploading(true);
            }}
            disabled={configMutation.isLoading}
          >
            {config.id ? "Submit" : "Create"}
          </Button>
        </Skeleton>
      </Box>
      <Grid container spacing={2} height={"100%"}>
        <Grid xs={12}>
          <Skeleton variant="inline" loading={isUploading}></Skeleton>
        </Grid>
        <Grid xs={12}>
          <Skeleton variant="inline" loading={isUploading}>
            <Card variant={"outlined"}>
              <CardContent>
                <Typography level="h4" component="div" sx={{ marginBottom: 2 }}>
                  SharePoint
                </Typography>
                <SharePointSection
                  value={config}
                  onChange={(updatedConfig) => setConfig(updatedConfig)}
                />
              </CardContent>
            </Card>
          </Skeleton>
        </Grid>
        <Grid xs={12}>
          <Skeleton variant="inline" loading={isUploading}>
            <Card variant={"outlined"}>
              <CardContent>
                <Typography level="h4" component="div" sx={{ marginBottom: 2 }}>
                  Client Settings
                </Typography>
                <ClientSettingsSection
                  value={config}
                  onChange={(updatedConfig) => setConfig(updatedConfig)}
                />
              </CardContent>
            </Card>
          </Skeleton>
        </Grid>
        {config.implementationType === ImplementationType.AzureBlob ? (
          <Grid xs={12}>
            <Skeleton variant="inline" loading={isUploading}>
              <Card variant={"outlined"}>
                <CardContent>
                  <Typography
                    level="h4"
                    component="div"
                    sx={{ marginBottom: 2 }}
                  >
                    Azure Blob
                  </Typography>
                  <AzureBlobSection
                    azureBlobConfig={config.azureBlobs?.at(0)}
                    onChange={(updatedBlob) =>
                      setConfig({
                        ...config,
                        azureBlobs: [updatedBlob],
                      })
                    }
                  />
                </CardContent>
              </Card>
            </Skeleton>
          </Grid>
        ) : (
          <></>
        )}
        <Grid xs={12}>
          <Skeleton variant="inline" loading={isUploading}>
            <Card variant={"outlined"}>
              <CardContent>
                <Typography level="h4" component="div" sx={{ marginBottom: 2 }}>
                  Migration Configuration
                </Typography>
                <MigrationConfigurationSection
                  value={config}
                  onChange={(updatedConfig) => setConfig(updatedConfig)}
                />
              </CardContent>
            </Card>
          </Skeleton>
        </Grid>
        <Grid xs={12}></Grid>
      </Grid>
    </form>
  );
};

export default ClientConfigurationForm;
