export interface FlaggingConfiguration {
  flagType: FlagType;
  flagProperties: FlagProperty[];
}

export enum FlagType {
  CaseWithMoreThan10kFiles = "caseWithMoreThan10kFiles",
  DicomCaseFile = "dicomCaseFile",
  MsgCaseFile = "msgCaseFile",
}

export const FlagTypeLabels: Record<FlagType, string> = {
  [FlagType.CaseWithMoreThan10kFiles]: "Cases with more than 10k files",
  [FlagType.DicomCaseFile]: "DICOM case files",
  [FlagType.MsgCaseFile]: "Flag case files with extension:",
};

export interface FlagProperty {
  key: string;
  value: string;
}
