import { Box, Chip, ChipDelete, Input, Textarea } from "@mui/joy";
import * as React from "react";

import OutlinedDiv from "../common/OutlinedDiv";

interface FileExtensionsInputProps {
  inputLabel: string;
  extensions: string[];
  maxExtensionsPerLine?: number | null;
  usePlaceholderAsLabel?: boolean
  onExtensionsChange: (value: string[]) => void;
  endDecorator?: React.ReactNode;
}
function addDotPrefixAndRemoveSpaces(input: string): string {
  const trimmedAndNoSpacesInput = input.replace(/\s/g, "");
  if (!trimmedAndNoSpacesInput.startsWith(".")) {
    return "." + trimmedAndNoSpacesInput;
  }
  return trimmedAndNoSpacesInput;
}
const FileExtensionsInput: React.FC<FileExtensionsInputProps> = ({
  inputLabel,
  extensions,
  onExtensionsChange: onExtensionChange,
  maxExtensionsPerLine = 4,
  usePlaceholderAsLabel = false,
  endDecorator = null
}) => {
    const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>, extensions: string[]) => {
        e.preventDefault();
        const value = e.target.value as string;
        e.target.value = "";
        if (value) {
          onExtensionChange(Array.from(new Set([...extensions, addDotPrefixAndRemoveSpaces(value)])));
        }
      };
      
      const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>, extensions: string[]) => {
        if (e.key === "Enter" || e.code === "Space") {
          e.preventDefault();
          const event = e as any;
          const value = event.target.value as string;
          event.target.value = "";
          if (value) {
            onExtensionChange(Array.from(new Set([...extensions, addDotPrefixAndRemoveSpaces(value)])));
        }
        }
      };
      
      const generateChips = (extensions: string[]) => (
        <Box flex="grow">
          {extensions.map((extension) => (
            <Chip
              key={`${inputLabel.replace(' ', '-')}-${extension}`}
              size="sm"
              variant="soft"
              color="primary"
              endDecorator={
                <ChipDelete
                  onDelete={(e) => {
                    e.preventDefault();
                    onExtensionChange(extensions.filter((f) => f !== extension));
                  }}
                />
              }
            >
              {extension}
            </Chip>
          ))}
        </Box>
      );
  return !usePlaceholderAsLabel ? (
    <OutlinedDiv label={inputLabel}>
        {(extensions?.length ?? 0) > (maxExtensionsPerLine ?? -1) ?
      <Textarea
        size="sm"
        onBlur={(e) => handleBlur(e, extensions)}
        onKeyDown={(e) => handleKeyDown(e, extensions)}
        startDecorator={generateChips(extensions)}
        endDecorator={endDecorator}
      /> : 
      <Input
        size="sm"
        fullWidth
        onBlur={(e) => handleBlur(e, extensions)}
        onKeyDown={(e) => handleKeyDown(e, extensions)}
        startDecorator={generateChips(extensions)}
        endDecorator={endDecorator}
      />
      }
    </OutlinedDiv>
  ) : (
    (extensions?.length ?? 0) > (maxExtensionsPerLine ?? Infinity) ?
      <Textarea
        size="sm"
        placeholder={inputLabel}
        onBlur={(e) => handleBlur(e, extensions)}
        onKeyDown={(e) => handleKeyDown(e, extensions)}
        startDecorator={generateChips(extensions)}
        endDecorator={endDecorator}
      /> : 
      <Input
        size="sm"
        placeholder={inputLabel}
        fullWidth
        onBlur={(e) => handleBlur(e, extensions)}
        onKeyDown={(e) => handleKeyDown(e, extensions)}
        startDecorator={generateChips(extensions)}
        endDecorator={endDecorator}
      />      
  );
};

export default FileExtensionsInput;
