import { Box, Button, Typography } from "@mui/joy";
import UsersTable from "../components/Users/UsersTable";
import { useState } from "react";
import { User } from "../models/User";
import { useMutation, useQueryClient } from "react-query";
import UsersRepository from "../repositories/UsersRepository";
import CreateUserModal from "../components/Users/CreateUserModal";
import ConfirmSaveChangesModal from "../components/common/ConfirmSaveChangesModal";
import UserCreatedModal from "../components/Users/UserCreatedModal";
import { useNavigate } from "react-router-dom";

interface CreateUser {
  username: string;
  email: string;
  password: string;
}

const UsersPage = () => {
  const [createdUser, setCreatedUser] = useState<User | null>(null);
  const [createUserModalOpened, setCreateUserModalOpened] =
    useState<boolean>(false);
  const [saveChangesModalOpened, setSaveChangesModalOpened] =
    useState<boolean>(false);
  const [deleteUserId, setDeleteUserId] = useState<string | null>(null);
  const navigate = useNavigate();

  const usersRepository = new UsersRepository();
  const queryClient = useQueryClient();

  const createUserMutation = useMutation(
    ["user", createdUser?.id],
    async (newUser: CreateUser) => {
      const createdUser = await usersRepository.createUser(
        newUser.username,
        newUser.email,
        newUser.password
      );
      return createdUser;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users"); // Invalidate the query to trigger a refetch
      },
    }
  );

  const handleConfirmDelete = async () => {
    if (deleteUserId) {
      try {
        await usersRepository.deleteUser(deleteUserId);
        await queryClient.invalidateQueries("users");
      } catch (error) {
        console.error("Failed to delete organization:", error);
      }
    }
    setDeleteUserId(null);
    setSaveChangesModalOpened(false);
  };

  const handleCreateUser = async function (
    username: string,
    email: string,
    password: string
  ): Promise<void> {
    const user = await createUserMutation.mutateAsync({
      username: username,
      email: email,
      password: password,
    });
    setCreatedUser(user);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Users</Typography>
        <Button
          color="primary"
          size="sm"
          onClick={() => setCreateUserModalOpened(true)}
        >
          Create User
        </Button>
      </Box>

      <UsersTable
        onDeleteUser={function (userId: string): void {
          setSaveChangesModalOpened(true);
          setDeleteUserId(userId);
        }}
      ></UsersTable>

      <CreateUserModal
        open={createUserModalOpened}
        onClose={function (): void {
          setCreateUserModalOpened(false);
        }}
        onCreateUser={handleCreateUser}
      />

      <UserCreatedModal
        open={createdUser !== null}
        onClose={function (): void {
          setCreatedUser(null);
        }}
        user={createdUser}
        onGoToUser={function (): void {
          navigate(`/users/${createdUser?.id}`);
        }}
      />

      <ConfirmSaveChangesModal
        isOpen={saveChangesModalOpened}
        onClose={function (): void {
          setDeleteUserId(null);
          setSaveChangesModalOpened(false);
        }}
        onSaveChanges={async function (): Promise<void> {
          if (deleteUserId) {
            await handleConfirmDelete();
            return;
          }
        }}
        message={() => {
          return "Are you sure you want to delete this user?";
        }}
      />
    </>
  );
};

export default UsersPage;
