import { Card, CardContent, Grid, Stack, Typography } from "@mui/joy";
import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import OutlinedDiv from "../common/OutlinedDiv";

interface PieChartData {
  data: { name: string; value: number; color: string }[];
  label: string;
}

interface StatisticsCardProps {
  migrationChartData: PieChartData;
  filesChartData: PieChartData;
  fileFlagsChartData?: PieChartData;
  title: string;
  totalFilesSizeInGb: number;
  totalMigratedFilesSizeInGb: number;
  totalFilesSizeInMb: number;
  totalMigratedFilesSizeInMb: number;
  totalFilesMovedToFirmDocsSizeInGb?: number;
  totalFilesMovedToFirmDocsSizeInMb?: number;
  notResolvedFlaggedFilesSizeInGb?: number;
  notResolvedFlaggedFilesSizeInMb?: number;
  pieChartSize?: number;
}

const StatisticsCard: React.FC<StatisticsCardProps> = ({
  migrationChartData,
  filesChartData,
  fileFlagsChartData,
  title,
  totalFilesSizeInGb,
  totalMigratedFilesSizeInGb,
  totalFilesSizeInMb,
  totalMigratedFilesSizeInMb,
  totalFilesMovedToFirmDocsSizeInGb,
  totalFilesMovedToFirmDocsSizeInMb,
  notResolvedFlaggedFilesSizeInGb,
  notResolvedFlaggedFilesSizeInMb,
  pieChartSize = 200,
}) => {
  const useGb = totalFilesSizeInGb > 1;
  const migratedPercentage =
    !isNaN(totalMigratedFilesSizeInMb) &&
    !isNaN(totalFilesSizeInMb) &&
    totalFilesSizeInMb !== 0
      ? `${((totalMigratedFilesSizeInMb / totalFilesSizeInMb) * 100).toFixed(
          2,
        )}%`
      : totalFilesSizeInMb === 0 && totalMigratedFilesSizeInMb === 0
      ? "100.00 %"
      : "0.00 %";
  const hasFlaggingData = fileFlagsChartData ? true : false;
  const movedToFirmDocsPercentage =
    !isNaN(totalFilesMovedToFirmDocsSizeInMb ?? 0) &&
    !isNaN(totalFilesSizeInMb) &&
    totalFilesSizeInMb !== 0
      ? `${(((totalFilesMovedToFirmDocsSizeInMb ?? 0) / totalFilesSizeInMb) * 100).toFixed(
          2,
        )}%`
      : totalFilesSizeInMb === 0 && totalMigratedFilesSizeInMb === 0
      ? "100.00 %"
      : "0.00 %";
  const notResolvedFlaggedPercentage =
    !isNaN(notResolvedFlaggedFilesSizeInMb ?? 0) &&
    !isNaN(totalFilesSizeInMb) &&
    totalFilesSizeInMb !== 0
      ? `${(((notResolvedFlaggedFilesSizeInMb ?? 0) / totalFilesSizeInMb) * 100).toFixed(
          2,
        )}%`
      : totalFilesSizeInMb === 0 && totalMigratedFilesSizeInMb === 0
      ? "100.00 %"
      : "0.00 %";
  const gridSize = fileFlagsChartData ? 4 : 6;
  return (
    <Card variant="outlined" sx={{ width: "100%" }}>
      <CardContent>
        <Typography level="h3" sx={{ paddingBottom: "20px" }}>
          {title}
        </Typography>
        <Grid container spacing={1}>
          <Grid xs={12}>
            <OutlinedDiv label="Files Size">
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography level="body-md" sx={{ color: "text.primary" }}>
                  {useGb ? (
                    <>
                      Migrated Files Size (GB):{" "}
                      {totalMigratedFilesSizeInGb.toFixed(2)} (
                      {migratedPercentage})
                    </>
                  ) : (
                    <>
                      Migrated Files Size (MB):{" "}
                      {totalMigratedFilesSizeInMb.toFixed(2)} (
                      {migratedPercentage})
                    </>
                  )}
                </Typography>
                {hasFlaggingData ? 
                <>
                <Typography level="body-md" sx={{ color: "text.primary" }}>
                  {useGb ? (
                    <>
                      Files Moved To Firm Documents Size (GB):{" "}
                      {(totalFilesMovedToFirmDocsSizeInGb ?? 0).toFixed(2)} (
                      {movedToFirmDocsPercentage})
                    </>
                  ) : (
                    <>
                      Files Moved To Firm Documents Size (MB):{" "}
                      {(totalFilesMovedToFirmDocsSizeInMb ?? 0).toFixed(2)} (
                      {movedToFirmDocsPercentage})
                    </>
                  )}
                </Typography>
                <Typography level="body-md" sx={{ color: "text.primary" }}>
                  {useGb ? (
                    <>
                      Not Migrated Flagged Files Size (GB):{" "}
                      {(notResolvedFlaggedFilesSizeInGb ?? 0).toFixed(2)} (
                      {notResolvedFlaggedPercentage})
                    </>
                  ) : (
                    <>
                      Not Migrated Flagged Files Size  (MB):{" "}
                      {(notResolvedFlaggedFilesSizeInMb ?? 0).toFixed(2)} (
                      {notResolvedFlaggedPercentage})
                    </>
                  )}
                </Typography>
                </> : <></>}
                <Typography level="body-md" sx={{ color: "text.primary" }}>
                  {useGb ? (
                    <>Total Files Size (GB): {totalFilesSizeInGb.toFixed(2)}</>
                  ) : (
                    <>Total Files Size (MB): {totalFilesSizeInMb.toFixed(2)}</>
                  )}
                </Typography>
              </Stack>
            </OutlinedDiv>
          </Grid>
          <Grid xs={gridSize}>
            <OutlinedDiv label={migrationChartData.label}>
              <Stack direction={"row"}>
                <Stack spacing={0} width={"50%"} maxWidth={"200px"}>
                  {migrationChartData.data.map((entry, index) => (
                    <Stack
                      key={`stack-${index}-${title}`}
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Typography
                        level="body-md"
                        sx={{ color: `${entry.color}` }}
                      >
                        {entry.name}:
                      </Typography>
                      <Typography
                        level="body-md"
                        sx={{ color: `${entry.color}` }}
                      >
                        {entry.value}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
                <ResponsiveContainer width={"100%"} height={pieChartSize}>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      data={migrationChartData.data}
                      cx={"50%"}
                      cy={"50%"}
                      outerRadius={"100%"}
                    >
                      {migrationChartData.data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}-${title}`}
                          fill={entry.color}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Stack>
            </OutlinedDiv>
          </Grid>
          <Grid xs={gridSize}>
            <OutlinedDiv label={filesChartData.label}>
              <Stack direction={"row"} justifyItems={"center"}>
                <Stack spacing={0} width={"50%"} maxWidth={"200px"}>
                  {filesChartData.data.map((entry, index) => (
                    <Stack
                      key={`stack-${index}-${title}1`}
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Typography
                        level="body-md"
                        sx={{ color: `${entry.color}` }}
                      >
                        {entry.name}:
                      </Typography>
                      <Typography
                        level="body-md"
                        sx={{ color: `${entry.color}` }}
                      >
                        {entry.value}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
                <ResponsiveContainer width={"100%"} height={pieChartSize}>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      data={filesChartData.data}
                      cx={"50%"}
                      cy={"50%"}
                      outerRadius={"100%"}
                    >
                      {filesChartData.data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}-${title}1`}
                          fill={entry.color}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Stack>
            </OutlinedDiv>
          </Grid>
          {hasFlaggingData ? 
          <Grid xs={gridSize}>
          <OutlinedDiv label={fileFlagsChartData?.label ?? ""}>
            <Stack direction={"row"} justifyItems={"center"}>
              <Stack spacing={0} width={"50%"} maxWidth={"200px"}>
                {fileFlagsChartData?.data.map((entry, index) => (
                  <Stack
                    key={`stack-${index}-${title}1`}
                    direction={"row"}
                    justifyContent={"space-between"}
                  >
                    <Typography
                      level="body-md"
                      sx={{ color: `${entry.color}` }}
                    >
                      {entry.name}:
                    </Typography>
                    <Typography
                      level="body-md"
                      sx={{ color: `${entry.color}` }}
                    >
                      {entry.value}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
              <ResponsiveContainer width={"100%"} height={pieChartSize}>
                <PieChart>
                  <Pie
                    dataKey="value"
                    data={fileFlagsChartData?.data}
                    cx={"50%"}
                    cy={"50%"}
                    outerRadius={"100%"}
                  >
                    {fileFlagsChartData?.data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}-${title}1`}
                        fill={entry.color}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Stack>
          </OutlinedDiv>
        </Grid> : <></>}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StatisticsCard;
