export enum MigrationEventStepType {
  Started = "started",
  Interrupted = "interrupted",
  Finished = "finished",
}

export const MigrationEventStepLabels: Record<MigrationEventStepType, string> = {
  [MigrationEventStepType.Started]: "Started",
  [MigrationEventStepType.Interrupted]: "Interrupted",
  [MigrationEventStepType.Finished]: "Finished",
};

export const getEventStepTypeColor = (stepType: MigrationEventStepType) => {
  switch (stepType) {
    case MigrationEventStepType.Started:
      return 'primary';
    case MigrationEventStepType.Finished:
      return 'success';
    case MigrationEventStepType.Interrupted:
      return 'danger';
    default:
      return 'neutral';
  }
}