import Delete from "@mui/icons-material/Delete";
import { Box, Grid, IconButton, Input, List, ListItem } from "@mui/joy";
import React, { useRef } from "react";
import { DriveMapping } from "../../models/ClientConfiguration/DriveMapping";
import AddableList from "../common/AddableList";

interface DriveMappingControlProps {
  driveMappings: DriveMapping[];
  onChange: (driveMappings: DriveMapping[]) => void;
}

const DriveMappingsControls: React.FC<DriveMappingControlProps> = ({
  driveMappings,
  onChange,
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const handleAddDriveMapping = () => {
    const newDriveMapping: DriveMapping = {
      mapFrom: "",
      mapTo: "",
    };
    const updatedDriveMappings = [...driveMappings, newDriveMapping];
    onChange(updatedDriveMappings);
  };

  const handleDeleteDriveMapping = (index: number) => {
    const updatedDriveMappings = [...driveMappings];
    updatedDriveMappings.splice(index, 1);
    onChange(updatedDriveMappings);
  };

  const handleDriveMappingChange = (
    index: number,
    field: keyof DriveMapping,
    value: string,
  ) => {
    const updatedDriveMappings = [...driveMappings];
    updatedDriveMappings[index][field] = value;
    onChange(updatedDriveMappings);
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      inputRefs.current[index].blur();
    }
  };

  return (
    <Box>
      <AddableList
        sx={{ overflow: "auto", maxHeight: "25vh" }}
        onAddClick={handleAddDriveMapping}
        subheaderText="Drive Mappings"
      >
        <List>
          {driveMappings.map((driveMapping, index) => (
            <ListItem
              endAction={
                <IconButton
                  aria-label="Delete"
                  size="sm"
                  color="danger"
                  onClick={() => handleDeleteDriveMapping(index)}
                >
                  <Delete />
                </IconButton>
              }
              key={index}
            >
              <Grid
                container
                spacing={1}
                width={"100%"}
                sx={{ paddingRight: "20px" }}
              >
                <Grid xs={6} minWidth={"50%"}>
                  <Input
                    fullWidth
                    size="sm"
                    placeholder="Map From"
                    value={driveMapping.mapFrom || ""}
                    onChange={(event) =>
                      handleDriveMappingChange(
                        index,
                        "mapFrom",
                        event.target.value,
                      )
                    }
                    onKeyDown={(event) =>
                      handleKeyPress(
                        event as React.KeyboardEvent<HTMLInputElement>,
                        index,
                      )
                    }
                  />
                </Grid>
                <Grid xs={6} minWidth={"50%"}>
                  <Input
                    fullWidth
                    size="sm"
                    placeholder="Map To"
                    value={driveMapping.mapTo || ""}
                    onChange={(event) =>
                      handleDriveMappingChange(
                        index,
                        "mapTo",
                        event.target.value,
                      )
                    }
                    onKeyDown={(event) =>
                      handleKeyPress(
                        event as React.KeyboardEvent<HTMLInputElement>,
                        index,
                      )
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </AddableList>
    </Box>
  );
};

export default DriveMappingsControls;
