import { Delete } from "@mui/icons-material";
import {
  Chip,
  FormControl,
  IconButton,
  Option,
  Select,
  Skeleton,
} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { ClientInfo } from "../../models/ClientInfo";
import ClientConfigurationRepository from "../../repositories/ClientConfigurationsRepository";
import ClientInfoRepository from "../../repositories/ClientInfoRepository";

interface ClientsTableProps {
  organizationId: string;
  onConfigChange: (clientId: string, newConfigId: string) => void;
  onDeleteClient: (clientId: string) => void;
}
interface ClientConfigSelectorProps {
  activeConfigId: string;
  organizationId: string;
  onConfigChange: (newConfigId: string) => void;
}
const ClientConfigSelector: React.FC<ClientConfigSelectorProps> = ({
  organizationId,
  activeConfigId,
  onConfigChange,
}) => {
  const [activeOrgId] = useState<string>(organizationId);
  const [selectedConfigId, setSelectedConfigId] = useState<string>(activeConfigId);
  const clientConfigurationsRepository = new ClientConfigurationRepository();
  const {
    data: configs = [],
    isLoading,
    error,
  } = useQuery(
    ["clientConfigurations", activeOrgId],
    () =>
      clientConfigurationsRepository.getClientConfigurations(activeOrgId),
    {
      staleTime: 1000 * 60 * 10,
      refetchInterval: 1000 * 60 * 5, // Set the interval for automatic refetching (in milliseconds)
      refetchOnMount: false, // Disable automatic refetching on component mount
      refetchOnWindowFocus: false,
    }, // Disable automatic refetching when the window regains focus
  );

  const handleSelectChange = (selectedValue: string) => {
    onConfigChange(selectedValue);
    setSelectedConfigId(selectedValue);
  };

  return (
    <FormControl>
      <Skeleton
        animation="pulse"
        variant="inline"
        loading={isLoading || !!error}
      >
        <Select
          value={selectedConfigId}
          onChange={(e, value) => handleSelectChange(value || "")}
        >
          {activeConfigId &&
            !(configs.findIndex((config) => config.id === selectedConfigId) > -1) && (
              <Option key={selectedConfigId} value={selectedConfigId}>
                {selectedConfigId}
              </Option>
            )}
          {configs.map((config) => (
            <Option key={config.id} value={config.id}>
              {config.name}
            </Option>
          ))}
        </Select>
      </Skeleton>
    </FormControl>
  );
};

const ClientsTable: React.FC<ClientsTableProps> = ({
  organizationId,
  onConfigChange,
  onDeleteClient,
}) => {
  const clientInfoRepository = new ClientInfoRepository();
  const { data: clientInfos = [], error } = useQuery<ClientInfo[]>(
    ["clientInfos", organizationId],
    () => clientInfoRepository.getClientInfos(organizationId), // Use the corresponding method from the repository
    {
      staleTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchIntervalInBackground: true,
    },
  );

  if (error) {
    return (
      <Typography>
        Error: {(error as { message?: string })?.message || "Unknown error"}
      </Typography>
    );
  }

  if (!clientInfos) {
    return <></>;
  }
  return (
    <React.Fragment>
      <Sheet
        className="ClientInfosTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: "5%",
                  minWidth: 48,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              ></th>
              <th style={{ width: "15%", minWidth: 140, padding: "12px 6px" }}>
                ID
              </th>
              <th style={{ width: "15%", minWidth: 140, padding: "12px 6px" }}>
                Client Code
              </th>
              <th style={{ width: "10%", minWidth: 120, padding: "12px 6px" }}>
                Status
              </th>
              <th style={{ width: "20%", minWidth: 160, padding: "12px 6px" }}>
                Active Configuration
              </th>
              <th
                style={{ width: "5%", minWidth: 160, padding: "12px 6px" }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {clientInfos.map((clientInfo) => (
              <tr key={clientInfo.id}>
                <td style={{ textAlign: "center", width: 120 }}></td>
                <td>
                  <Typography level="body-md">{clientInfo.id}</Typography>
                </td>
                <td>
                  <Typography level="body-md">
                    {clientInfo.clientCode}
                  </Typography>
                </td>
                <td>
                  <Chip
                    variant="soft"
                    size="sm"
                    color={clientInfo.isActive ? "success" : "danger"}
                  >
                    {clientInfo.isActive ? "Active" : "Inactive"}
                  </Chip>
                </td>
                <td>
                  <ClientConfigSelector
                    organizationId={organizationId}
                    activeConfigId={clientInfo.activeConfiguration}
                    onConfigChange={(newConfigId) =>{
                      onConfigChange(clientInfo.id, newConfigId)
                    }
                  }
                  ></ClientConfigSelector>
                </td>
                <td>
                  <IconButton
                    aria-label="Delete"
                    size="sm"
                    color="danger"
                    onClick={() => onDeleteClient(clientInfo.id)}
                  >
                    <Delete />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </React.Fragment>
  );
  // ...
};

export default ClientsTable;
