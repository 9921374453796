import * as React from "react";

import { FileTypeFilter } from "../../models/ClientConfiguration/FileTypeFilter";
import FileExtensionsInput from "../common/FileExtensionsInput";

interface FileExtensionFilterInputProps {
  filters: FileTypeFilter[];
  onChange: (value: FileTypeFilter[]) => void;
}
const FileExtensionsFilterInput: React.FC<FileExtensionFilterInputProps> = ({
  filters,
  onChange,
}) => {
  return (
    <FileExtensionsInput 
      inputLabel="File Extension Filters"
      extensions={filters.map(f => f.fileExtension ?? "")}
      onExtensionsChange={(newExtensions) => {
        onChange(newExtensions.map(e => {
          return {fileExtension: e}
        }))
      }}
      />
  );
};

export default FileExtensionsFilterInput;
