import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { Button, IconButton } from "@mui/joy";
import Box from "@mui/joy/Box";
import Modal from "@mui/joy/Modal";
import Typography from "@mui/joy/Typography";
import React from "react";
import { OrganizationWithSecret } from "../../models/Organization";

interface OrganizationCreatedModalProps {
  open: boolean;
  onClose: () => void;
  organization: OrganizationWithSecret | null;
  onGoToOrganization: () => void; // Handler for "Go to organization" button click
}

const OrganizationCreatedModal: React.FC<OrganizationCreatedModalProps> = ({
  open,
  onClose,
  organization,
  onGoToOrganization,
}) => {
  const handleClose = () => {
    onClose();
  };

  const handleCopyToClipboard = () => {
    if (organization) {
      navigator.clipboard.writeText(organization.organizationSecret);
    }
  };

  const handleGoToOrganization = () => {
    onGoToOrganization();
    onClose();
  };

  if (!organization) {
    return null;
  }

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: 300,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CheckCircleOutlineIcon
              sx={{ fontSize: 64, color: "success", marginRight: 2 }}
            />
            <Typography level="h4">Organization Created!</Typography>
          </Box>
          <Typography>
            <strong>ID:</strong> {organization.id}
          </Typography>
          <Typography>
            <strong>Name:</strong> {organization.name}
          </Typography>
          <Typography>
            <strong>Intact ID:</strong> {organization.intactId}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography sx={{ flexGrow: 1 }}>
              <strong>Secret:</strong> {organization.organizationSecret}
            </Typography>
            <IconButton onClick={handleCopyToClipboard}>
              <FileCopyOutlinedIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button onClick={handleGoToOrganization}>Go to organization</Button>
            <Button onClick={handleClose} variant="outlined">
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default OrganizationCreatedModal;
