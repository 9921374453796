export enum CaseMigrationStrategy {
  AllCases = "allCases",
  Custom = "custom",
  DateAscending = "dateAscending",
  DateDescending = "dateDescending",
  BasicPlus = "basicPlus",
}

export const CaseMigrationStrategyLabels: Record<
  CaseMigrationStrategy,
  string
> = {
  [CaseMigrationStrategy.AllCases]: "All Cases",
  [CaseMigrationStrategy.Custom]: "Custom",
  [CaseMigrationStrategy.DateAscending]: "Date Ascending",
  [CaseMigrationStrategy.DateDescending]: "Date Descending",
  [CaseMigrationStrategy.BasicPlus]: "Basic Plus",
};
