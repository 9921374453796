import { FormControl, FormLabel, Stack, Switch } from "@mui/joy";
import React from "react";
import { FlagType, FlagTypeLabels, FlaggingConfiguration } from "../../models/ClientConfiguration/FlaggingConfiguration";
import OutlinedDiv from "../common/OutlinedDiv";

interface FlagTypeSelectProps {
  value: FlaggingConfiguration[];
  onChange: (value: FlaggingConfiguration[]) => void;
}

const FlagTypeSelect: React.FC<FlagTypeSelectProps> = ({ value, onChange }) => {
  const handleSwitchChange = (isChecked: boolean, flagValue: FlaggingConfiguration) => {
    let updatedFlags: FlaggingConfiguration[];

    if (isChecked) {
      updatedFlags = [...value, flagValue];
    } else {
      updatedFlags = value.filter((flag) => flag.flagType !== flagValue.flagType);
    }

    onChange(updatedFlags);
  };

  return (
    <OutlinedDiv label="Flag Types">
      <Stack
        height={"100%"}
        spacing={1}
        sx={{ display: "flex", flexDirection: "column" }}
        justifyContent={"center"}
      >
        {Object.values(FlagType)
          .filter((value) => value !== FlagType.MsgCaseFile)
          .map((flag) => {
            const flagValue = flag as FlagType;
            return (
              <FormControl
                key={flagValue}
                orientation="horizontal"
                sx={{ width: "100%", justifyContent: "space-between" }}
              >
                <FormLabel>{FlagTypeLabels[flagValue]}</FormLabel>
                <Switch
                  checked={value.some( flag => flag.flagType === flagValue)}
                  onChange={(event) =>
                    handleSwitchChange(event.target.checked, {flagType: flagValue} as FlaggingConfiguration)
                  }
                />
              </FormControl>
            );
          })}
      </Stack>
    </OutlinedDiv>
  );
};

export default FlagTypeSelect;
