import Box from "@mui/joy/Box";
import CssBaseline from "@mui/joy/CssBaseline";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/common/Sidebar";
import { useAuth } from "../contexts/AuthContext";
import CasePage from "./CasePage";
import CasesPage from "./CasesPage";
import ClientConfigurationPage from "./ClientConfigurationPage";
import ClientConfigurationsPage from "./ClientConfigurationsPage";
import ClientsPage from "./ClientsPage";
import OrganizationPage from "./OrganizationPage";
import OrganizationsPage from "./OrganizationsPage";
import { useOrganization } from "../contexts/OrganizationContext";
import UsersPage from "./UsersPage";
import UserPage from "./UserPage";

interface DashboardPageProps {
  selectedNavigation: string;
}

const DashboardPage: React.FC<DashboardPageProps> = ({
  selectedNavigation: selectedItem,
}) => {
  const { organizationId, configurationId, userId } = useParams<{
    organizationId?: string;
    configurationId?: string;
    userId?: string;
  }>();

  const navigate = useNavigate();
  const auth = useAuth();
  const { setSelectedOrganizationId } = useOrganization();

  useEffect(() => {
    if (!auth.isAuthenticated) {
      navigate("/login");
    }

    if (organizationId !== undefined) {
      setSelectedOrganizationId(organizationId);
    } else {
      setSelectedOrganizationId(null);
    }

    return () => {};
  }, [auth.isAuthenticated, navigate]);

  if (!auth.isAuthenticated) {
    return <></>;
  }

  let contentComponent;
  switch (selectedItem) {
    case "Organizations":
      contentComponent = <OrganizationsPage />;
      break;
    case "Overview":
      contentComponent = (
        <OrganizationPage organizationId={organizationId ?? ""} />
      );
      break;
    case "Client Configurations":
      contentComponent = (
        <ClientConfigurationsPage organizationId={organizationId ?? ""} />
      );
      break;
    case "Client Configuration":
      contentComponent = (
        <ClientConfigurationPage
          configurationId={configurationId}
          organizationId={organizationId}
        />
      );
      break;
    case "Clients":
      contentComponent = <ClientsPage />;
      break;
    case "Cases":
      contentComponent = <CasesPage />;
      break;
    case "Case":
      contentComponent = <CasePage />;
      break;
    case "Users":
      contentComponent = <UsersPage />;
      break;
    case "UserDetails":
      contentComponent = <UserPage userId={userId ?? ""} />;
      break;
    default:
      contentComponent = null;
  }

  return (
    <>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
        <Sidebar selectedItem={selectedItem} />
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: {
              xs: 2,
              md: 6,
            },
            pt: {
              xs: 3,
            },
            pb: {
              xs: 2,
              sm: 2,
              md: 3,
            },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
            overflow: "auto",
          }}
        >
          {contentComponent}
        </Box>
      </Box>
    </>
  );
};

export default DashboardPage;
