import { Box, Chip, FormControl, Option, Select } from "@mui/joy";
import ChipDelete from "@mui/joy/ChipDelete";
import { Typography } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import {
  RuntimeScheduleType,
  RuntimeScheduleTypeLabels,
  deserializeRuntimeScheduleTypes,
  serializeRuntimeScheduleTypes,
} from "../../models/ClientConfiguration/RuntimeScheduleType";

const RuntimeScheduleTypeSelect: React.FC<{
  value: number;
  onChange: (value: number) => void;
}> = ({ value, onChange }) => {
  const [selectedTypes, setSelectedTypes] = useState<RuntimeScheduleType[]>(
    deserializeRuntimeScheduleTypes(value),
  );
  const [isAdding, setIsAdding] = useState<boolean>(false);

  const handleTypeChange = (value: RuntimeScheduleType[]) => {
    const selectedValues = value as RuntimeScheduleType[];
    const filteredValues = filterScheduleTypes(selectedValues);
    setSelectedTypes(filteredValues);
    onChange(serializeRuntimeScheduleTypes(filteredValues));
  };
  const filterScheduleTypes = (
    selectedTypes: RuntimeScheduleType[],
  ): RuntimeScheduleType[] => {
    let filteredTypes: RuntimeScheduleType[] = [...selectedTypes];

    // Check if both Saturday and Sunday are selected
    if (
      filteredTypes.includes(RuntimeScheduleType.Saturday) &&
      filteredTypes.includes(RuntimeScheduleType.Sunday)
    ) {
      // Replace with Weekends
      filteredTypes = filteredTypes.filter(
        (type) =>
          ![RuntimeScheduleType.Saturday, RuntimeScheduleType.Sunday].includes(
            type,
          ),
      );
      filteredTypes = [...filteredTypes, RuntimeScheduleType.Weekends];
    }

    // Check if Monday to Friday are selected
    if (
      filteredTypes.includes(RuntimeScheduleType.Monday) &&
      filteredTypes.includes(RuntimeScheduleType.Tuesday) &&
      filteredTypes.includes(RuntimeScheduleType.Wednesday) &&
      filteredTypes.includes(RuntimeScheduleType.Thursday) &&
      filteredTypes.includes(RuntimeScheduleType.Friday)
    ) {
      // Replace with WorkDays
      filteredTypes = filteredTypes.filter(
        (type) =>
          ![
            RuntimeScheduleType.Monday,
            RuntimeScheduleType.Tuesday,
            RuntimeScheduleType.Wednesday,
            RuntimeScheduleType.Thursday,
            RuntimeScheduleType.Friday,
          ].includes(type),
      );
      filteredTypes = [...filteredTypes, RuntimeScheduleType.WorkDays];
    }

    // Check if all days are selected
    if (
      filteredTypes.includes(RuntimeScheduleType.Monday) &&
      filteredTypes.includes(RuntimeScheduleType.Tuesday) &&
      filteredTypes.includes(RuntimeScheduleType.Wednesday) &&
      filteredTypes.includes(RuntimeScheduleType.Thursday) &&
      filteredTypes.includes(RuntimeScheduleType.Friday) &&
      filteredTypes.includes(RuntimeScheduleType.Saturday) &&
      filteredTypes.includes(RuntimeScheduleType.Sunday)
    ) {
      // Remove all days and replace with Daily
      return [RuntimeScheduleType.Daily];
    }

    // Check if WorkDays is selected
    if (filteredTypes.includes(RuntimeScheduleType.WorkDays)) {
      // Remove Monday to Friday
      filteredTypes = filteredTypes.filter(
        (type) =>
          ![
            RuntimeScheduleType.Monday,
            RuntimeScheduleType.Tuesday,
            RuntimeScheduleType.Wednesday,
            RuntimeScheduleType.Thursday,
            RuntimeScheduleType.Friday,
          ].includes(type),
      );
    }

    // Check if Weekends is selected
    if (filteredTypes.includes(RuntimeScheduleType.Weekends)) {
      // Remove Saturday and Sunday
      filteredTypes = filteredTypes.filter(
        (type) =>
          ![RuntimeScheduleType.Saturday, RuntimeScheduleType.Sunday].includes(
            type,
          ),
      );
    }
    // Check if both WorkDays and Weekends are selected
    if (
      (filteredTypes.includes(RuntimeScheduleType.WorkDays) &&
        filteredTypes.includes(RuntimeScheduleType.Weekends)) ||
      filteredTypes.includes(RuntimeScheduleType.Daily)
    ) {
      return [RuntimeScheduleType.Daily];
    }
    return filteredTypes;
  };
  const handleDelete = (deletedValue: RuntimeScheduleType) => {
    handleTypeChange(selectedTypes.filter((t) => t !== deletedValue));
  };
  return (
    <Box width={"100%"}>
      <FormControl>
        <Select
          size={"sm"}
          listboxOpen={isAdding}
          placeholder={selectedTypes.length > 0 ? false : "Select Types"}
          sx={{ minWidth: "200px" }}
          // value={(serializeRuntimeScheduleTypes(selectedTypes))}
          onListboxOpenChange={(isOpen) => {
            setIsAdding(isOpen);
          }}
          // onChange={(event, value) => handleTypeChange(deserializeRuntimeScheduleTypes(value || 0))}
          startDecorator={
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selectedTypes.map((val) => (
                <Chip
                  size="sm"
                  endDecorator={
                    <ChipDelete onDelete={() => handleDelete(val)} />
                  }
                >
                  <Typography>
                    {RuntimeScheduleTypeLabels[val as RuntimeScheduleType]}
                  </Typography>
                </Chip>
              ))}
            </Box>
          }
        >
          {Object.values(RuntimeScheduleType)
            .filter((value) => typeof value === "number")
            .map((type) => (
              <Option
                key={type}
                value={type}
                className={clsx({
                  "Mui-selected": selectedTypes.includes(
                    type as RuntimeScheduleType,
                  ),
                })}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  const newSelected = selectedTypes.includes(
                    type as RuntimeScheduleType,
                  )
                    ? selectedTypes.filter((t) => t !== type)
                    : [...selectedTypes, type as RuntimeScheduleType];
                  handleTypeChange(newSelected);
                }}
              >
                {RuntimeScheduleTypeLabels[type as RuntimeScheduleType]}
              </Option>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default RuntimeScheduleTypeSelect;
