export const customColors = {
  pink: {
    50: '#fdf2f8',
    100: '#fce7f3',
    200: '#fbcfe8',
    300: '#f9a8d4',
    400: '#f472b6',
    500: '#ec4899',
    600: '#db2777',
    700: '#be185d',
    800: '#9d174d',
    900: '#831843',
    solidBg: '#f472b6',
    solidActiveBg: '#ec4899',
    outlinedBorder: '#ec4899',
    outlinedColor: '#be185d',
    outlinedActiveBg: '#fce7f3',
    softColor: '#9d174d',
    softBg: '#fbcfe8',
    softActiveBg: '#f9a8d4',
    plainColor: '#be185d',
    plainActiveBg: '#fce7f3',
  },
  lime: {
    50: '#f7fee7',
    100: '#ecfccb',
    200: '#d9f99d',
    300: '#bef264',
    400: '#a3e635',
    500: '#84cc16',
    600: '#65a30d',
    700: '#4d7c0f',
    800: '#3f6212',
    900: '#365314',
    solidBg: '#a3e635',
    solidActiveBg: '#84cc16',
    outlinedBorder: '#84cc16',
    outlinedColor: '#4d7c0f',
    outlinedActiveBg: '#ecfccb',
    softColor: '#3f6212',
    softBg: '#d9f99d',
    softActiveBg: '#bef264',
    plainColor: '#4d7c0f',
    plainActiveBg: '#ecfccb',
  },
  yellow: {
    50: '#fefce8',
    100: '#fef9c3',
    200: '#fef08a',
    300: '#fde047',
    400: '#facc15',
    500: '#eab308',
    600: '#ca8a04',
    700: '#a16207',
    800: '#854d0e',
    900: '#713f12',
    solidBg: '#facc15',
    solidActiveBg: '#eab308',
    outlinedBorder: '#eab308',
    outlinedColor: '#a16207',
    outlinedActiveBg: '#fef9c3',
    softColor: '#854d0e',
    softBg: '#fef08a',
    softActiveBg: '#fde047',
    plainColor: '#fef08a',
    plainActiveBg: '#fef9c3',
  },
};
