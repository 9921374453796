import { AxiosResponse } from "axios";
import { Case, CaseWithStatistics } from "../models/Case";
import { CaseFile } from "../models/CaseFile";
import { Paging } from "../models/Paging";
import useApi from "../services/ApiService";
import {MigrationFlagStatus} from "../models/Flags/MigrationFlagStatus";

interface GetCasesQueryOptions {
  organizationId?: string;
  status?: string[] | null;
  createdBy?: string[];
  caseNumber?: string[] | null;
  ids?: string[];
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
}
interface GetCaseFilesForCaseQueryOptions {
  status?: string[] | null;
  extension?: string[] | null;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
}

export class CasesRepository {
  private api = useApi();

  async getCases(
    options: GetCasesQueryOptions = {}
  ): Promise<{ data: Case[]; paging: Paging | null }> {
    const {
      organizationId = null,
      status = null,
      createdBy = null,
      caseNumber = null,
      ids = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
    } = options;

    try {
      const response = await this.api.get("/api/Cases", {
        params: {
          OrganizationId: organizationId,
          Status: status,
          CreatedBy: createdBy,
          CaseNumber: caseNumber,
          Id: ids,
          PageNumber: pageNumber,
          PageSize: pageSize,
          OrderBy: orderBy,
          Descending: descending,
        },
        paramsSerializer: {
          indexes: true,
        },
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }

      return { data, paging };
    } catch (error) {
      // Handle error
      throw new Error("Failed to fetch cases");
    }
  }

  async getCase(id: string): Promise<CaseWithStatistics> {
    try {
      const response = await this.api.get(`/api/Cases/${id}`);
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch case with id: ${id}`);
    }
  }
  async getCaseFilesForCase(
    id: string,
    options: GetCaseFilesForCaseQueryOptions = {}
  ): Promise<{ data: CaseFile[]; paging: Paging | null }> {
    const {
      status = null,
      extension = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
    } = options;
    try {
      const response = await this.api.get(`/api/Cases/${id}/files`, {
        params: {
          Status: status,
          Extension: extension,
          PageNumber: pageNumber,
          PageSize: pageSize,
          OrderBy: orderBy,
          Descending: descending,
        },
        paramsSerializer: {
          indexes: true,
        },
        timeout: 100 * 1000,
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }
      return { data, paging };
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch case with id: ${id}`);
    }
  }
  async updateCaseFlag(flagId: string, flagStatus: MigrationFlagStatus): Promise<void> {
    try {
      const response: AxiosResponse<void> = await this.api.patch(
        `/api/Cases/updateFlag`,
        { flagId: flagId, flagStatus: flagStatus }
      );
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(`Failed to update status of case with id: ${flagId}`);
    }
  }
}

export default CasesRepository;
