import React, { FC } from "react";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { Box, Chip } from "@mui/joy";

export interface MultipleSelectOption {
  id: string;
  label: string;
}

interface GenericMultipleSelectProps<T extends MultipleSelectOption> {
  options: T[];
  placeholder: string;
  inputValues: T[];
  setValues: (values: T[]) => void;
  width?: string;
}

const GenericMultipleSelect: FC<GenericMultipleSelectProps<MultipleSelectOption>> = ({
  options,
  placeholder,
  inputValues,
  setValues,
  width = "100%",
}) => {
  const handleChange = (
    event:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element, Element>
      | null,
    ids: string[] | null
  ) => {
    const newOptions = options.filter((option) =>
      ids?.find((id) => id === option.id)
    );
    if (newOptions) {
      setValues(newOptions);
    }
  };

  return (
    <Select
      multiple
      defaultValue={inputValues.map((x) => x.id)}
      value={inputValues.map((x) => x.id)}
      onChange={handleChange}
      placeholder={placeholder}
      sx={{ width, minWidth: "5rem" }}
      renderValue={(selected) => (
        <Box sx={{ display: "flex", gap: "0.25rem" }}>
          {selected.map((selectedOption) => (
            <Chip variant="soft" color="primary" key={selectedOption.id}>
              {selectedOption.label}
            </Chip>
          ))}
        </Box>
      )}
      slotProps={{
        listbox: {
          sx: {
            width: "100%",
          },
        },
      }}
    >
      {options.map((option) => (
        <Option key={option.id} value={option.id}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default GenericMultipleSelect;
