import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button, IconButton } from "@mui/joy";
import Box from "@mui/joy/Box";
import Modal from "@mui/joy/Modal";
import Typography from "@mui/joy/Typography";
import React from "react";
import { User } from "../../models/User";

interface UserCreatedModalProps {
  open: boolean;
  onClose: () => void;
  user: User | null;
  onGoToUser: () => void;
}

const UserCreatedModal: React.FC<UserCreatedModalProps> = ({
  open,
  onClose,
  user,
  onGoToUser,
}) => {
  const handleClose = () => {
    onClose();
  };

  const handleGoToOrganization = () => {
    onGoToUser();
    onClose();
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: 300,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CheckCircleOutlineIcon
              sx={{ fontSize: 64, color: "success", marginRight: 2 }}
            />
            <Typography level="h4">User Created!</Typography>
          </Box>
          <Typography>
            <strong>ID:</strong> {user.id}
          </Typography>
          <Typography>
            <strong>Userame:</strong> {user.username}
          </Typography>
          <Typography>
            <strong>Email:</strong> {user.email}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button onClick={handleGoToOrganization}>Go to user details</Button>
            <Button onClick={handleClose} variant="outlined">
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UserCreatedModal;
