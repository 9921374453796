import { CloseRounded } from "@mui/icons-material";
import {
  IconButton,
  Option,
  Select,
  SelectSlotsAndSlotProps,
  SelectStaticProps,
} from "@mui/joy";
import { ReactNode, useRef, useState } from "react";

interface ClearableSelectProps
  extends SelectSlotsAndSlotProps<boolean>,
    SelectStaticProps {
  onChange?: (newValue: string | null) => void;
  placeholder: string;
  value?: string | null;
}
declare module "@mui/joy/IconButton" {
  interface IconButtonPropsSizeOverrides {
    xs: true;
    xl: true;
  }
}

export function ClearableSelect({
  children,
  placeholder,
  onChange,
  value,
  color
}: ClearableSelectProps) {
  const [selectedValue, setSelectedValue] = useState<string | null>(value ?? null);
  const action: SelectStaticProps["action"] = useRef(null);
  const handleValueChange = (newValue: string | null) => {
    setSelectedValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Select
      action={action}
      value={selectedValue}
      placeholder={placeholder}
      color={color}
      onChange={(e, newValue) => handleValueChange(newValue)}
      {...(selectedValue && {
        endDecorator: (
          <IconButton
            size="xs"
            variant="plain"
            color={color}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            onClick={() => {
              handleValueChange(null);
              action.current?.focusVisible();
            }}
          >
            <CloseRounded />
          </IconButton>
        ),
        indicator: null,
      })}
      sx={{ minWidth: 160 }}
    >
      {children}
    </Select>
  );
}

export interface OptionProps {
  value: string;
  children: ReactNode;
}

export { Option };

