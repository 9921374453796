import { useTheme } from "@emotion/react";
import React from "react";
import StatisticsCard from "../common/StatisticsCard";
import {IntakesStatistics} from "../../models/Statistics/IntakesStatistics";

interface IntakesStatisticsCardProps {
  statistics: IntakesStatistics;
}

const IntakesStatisticsCard: React.FC<IntakesStatisticsCardProps> = ({
  statistics,
}) => {
  const theme = useTheme() as any;
  const intakeChartData = [
    {
      name: "Migrated",
      value: statistics.intakesMigrated,
      color: theme.palette.success.plainColor,
    },
    {
      name: "In Progress",
      value: statistics.intakesInProgress,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: statistics.intakesInterrupted,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value:
        statistics.numberOfIntakes -
        (statistics.intakesMigrated +
          statistics.intakesInterrupted +
          statistics.intakesInProgress),
      color: theme.palette.text.secondary,
    },
    {
      name: "Flagged",
      value: statistics.intakesFlagged - (statistics.intakesAcknowledged + statistics.intakesResolved),
      color: theme.palette.primary.plainColor,
    },
    {
      name: "Allowed",
      value: statistics.intakesResolved,
      color: theme.palette.lime.solidBg,
    },
    {
      name: "Acknowledged",
      value: statistics.intakesAcknowledged,
      color: theme.palette.yellow.plainColor,
    },
  ];
  const filesChartData = [
    {
      name: "Migrated",
      value: statistics.filesMigrated,
      color: theme.palette.success.plainColor,
    },
    {
      name: "Not Found",
      value: statistics.filesNotFound,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: statistics.filesInterrupted,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value:
        statistics.totalNumberOfFiles -
        (statistics.filesMigrated +
          statistics.filesInterrupted +
          statistics.filesNotFound +
          statistics.filesFlagged),
      color: theme.palette.text.secondary,
    },
    {
      name: "Flagged",
      value: statistics.filesFlagged,
      color: theme.palette.primary.plainColor,
    },
  ];
  return (
    <StatisticsCard
      migrationChartData={{ data: intakeChartData, label: "Intakes" }}
      filesChartData={{ data: filesChartData, label: "Files" }}
      title={"Intakes Statistics"}
      totalFilesSizeInGb={statistics.totalFilesSizeInGb}
      totalMigratedFilesSizeInGb={statistics.totalMigratedFilesSizeInGb}
      totalFilesSizeInMb={statistics.totalFilesSizeInMb}
      totalMigratedFilesSizeInMb={statistics.totalMigratedFilesSizeInMb}
    />
  );
};

export default IntakesStatisticsCard;
