import { useTheme } from "@emotion/react";
import React from "react";
import { FirmDocumentFoldersStatistics } from "../../models/Statistics/FirmDocumentFoldersStatistics";
import StatisticsCard from "../common/StatisticsCard";

interface FirmDocumentsStatisticsCardProps {
  statistics: FirmDocumentFoldersStatistics;
}

const FirmDocumentsStatisticsCard: React.FC<
  FirmDocumentsStatisticsCardProps
> = ({ statistics }) => {
  const theme = useTheme() as any;
  const caseChartData = [
    {
      name: "Migrated",
      value: statistics.foldersMigrated,
      color: theme.palette.success.plainColor,
    },
    {
      name: "In Progress",
      value: statistics.foldersInProgress,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: statistics.foldersInterrupted,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value:
        statistics.numberOfFolders -
        (statistics.foldersMigrated +
          statistics.foldersInterrupted +
          statistics.foldersInProgress),
      color: theme.palette.text.secondary,
    },
  ];
  const filesChartData = [
    {
      name: "Migrated",
      value: statistics.filesMigrated,
      color: theme.palette.success.plainColor,
    },
    {
      name: "Not Found",
      value: statistics.filesNotFound,
      color: theme.palette.warning.plainColor,
    },
    {
      name: "Interrupted",
      value: statistics.filesInterrupted,
      color: theme.palette.danger.plainColor,
    },
    {
      name: "Not Started",
      value:
        statistics.totalNumberOfFiles -
        (statistics.filesMigrated +
          statistics.filesInterrupted +
          statistics.filesNotFound),
      color: theme.palette.text.secondary,
    },
  ];
  return (
    <StatisticsCard
      migrationChartData={{ data: caseChartData, label: "Firm Documents" }}
      filesChartData={{ data: filesChartData, label: "Files" }}
      title={"Firm Documents Statistics"}
      totalFilesSizeInGb={statistics.totalFilesSizeInGb}
      totalMigratedFilesSizeInGb={statistics.totalMigratedFilesSizeInGb}
      totalFilesSizeInMb={statistics.totalFilesSizeInMb}
      totalMigratedFilesSizeInMb={statistics.totalMigratedFilesSizeInMb}
    />
  );
};

export default FirmDocumentsStatisticsCard;
