import { Box } from "@mui/joy";
import React from "react";

import { useQuery } from "react-query";
import ClientConfigurationForm from "../components/ClientConfiguration/ClientConfigurationForm";
import { CaseMigrationStrategy } from "../models/ClientConfiguration/CaseMigrationStrategy";
import { ClientConfiguration } from "../models/ClientConfiguration/ClientConfiguration";
import { FlagType } from "../models/ClientConfiguration/FlaggingConfiguration";
import { MigrationStrategy } from "../models/ClientConfiguration/MigrationStrategy";
import ClientConfigurationsRepository from "../repositories/ClientConfigurationsRepository";
interface ClientConfigurationPageProps {
  configurationId?: string;
  organizationId?: string;
}
const ClientConfigurationPage: React.FC<ClientConfigurationPageProps> = ({
  configurationId,
  organizationId,
}) => {
  const configurationsRepository = new ClientConfigurationsRepository();
  let selectedConfig = undefined;
  const {
    data: configuration,
    isLoading,
    isError,
    error,
  } = useQuery(
    ["clientConfigurations", configurationId],
    async () => {
      if (configurationId) {
        const config = await configurationsRepository.getClientConfiguration(
          configurationId ?? "",
        );
        return config;
      }
    },
    {
      enabled: !!configurationId,
      refetchInterval: 2 * 60 * 1000, // Refetch data every 2 minutes
      refetchIntervalInBackground: false, // Refetch data even if the component is not in focus
    },
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {(error as Error).message}</div>;
  }
  selectedConfig =
    !!configurationId && configuration
      ? configuration
      : {
          id: "",
          organizationId: organizationId ?? "",
          name: "",
          isDefault: true,
          dateCreated: "",
          migrateCases: true,
          migrateProviders: true,
          migrateTemplateFiles: false,
          migrateFirmDocuments: false,
          migrateIntakes: false,
          discoverCases: true,
          discoverProviders: true,
          discoverTemplateFiles: false,
          discoverFirmDocuments: false,
          discoverIntakes: false,
          uploadStarterTemplates: false,
          uploadFilesMetadataToSharepoint: true,
          sendDebugLogs: false,
          casesFromDay: null,
          casesMigrationStrategy: [
            CaseMigrationStrategy.AllCases,
            CaseMigrationStrategy.DateDescending,
          ],
          providersMigrationStrategy: MigrationStrategy.DateDescending,
          firmDocumentsMigrationStrategy: MigrationStrategy.DateDescending,
          templateFilesMigrationStrategy: MigrationStrategy.DateDescending,
          sharepointClientId: "ca64f31f-2781-418c-9f74-7eae4c9b4665",
          sharepointClientSecret: "NGY8Q~KnI4MvXfc8wUaUrypXtzx3-0SbKxLozbFO",
          flags: [

            {flagType: FlagType.CaseWithMoreThan10kFiles},
            { flagType: FlagType.DicomCaseFile },
            { flagType: FlagType.MsgCaseFile, flagProperties: [{key: "extension", value: ".msg"}] },
          ],
          sharepointRootSiteName: "CloudDocs",
          casesSharepointRootDriveName: "CaseFiles",
          providersSharepointRootDriveName: "ProviderFiles",
          firmDocumentsSharepointRootDriveName: "FirmDocuments",
          templateFilesSharepointRootDriveName: "TemplateFiles",
          fileTypeFilters: [ {fileExtension: ".wpd"}]
        } as ClientConfiguration;
  return (
    <Box minWidth="100%" height={"100%"} padding={0}>
      <ClientConfigurationForm configuration={selectedConfig} />
    </Box>
  );
};

export default ClientConfigurationPage;
