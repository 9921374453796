import Delete from "@mui/icons-material/Delete";
import { Box, Grid, IconButton, Input, ListItem } from "@mui/joy";
import React from "react";
import { DocTypeMapping } from "../../models/ClientConfiguration/DocTypeMapping";
import AddableList from "../common/AddableList";

interface DocTypeMappingControlProps {
  docTypeMappings: DocTypeMapping[];
  onChange: (docTypeMappings: DocTypeMapping[]) => void;
}

const DocTypeMappingsControl: React.FC<DocTypeMappingControlProps> = ({
  docTypeMappings,
  onChange,
}) => {
  const handleAddDocTypeMapping = () => {
    const newDocTypeMapping: DocTypeMapping = {
      mapFrom: "",
      mapTo: "",
    };
    const updatedDocTypeMappings = [...docTypeMappings, newDocTypeMapping];
    onChange(updatedDocTypeMappings);
  };

  const handleDeleteDocTypeMapping = (index: number) => {
    const updatedDocTypeMappings = [...docTypeMappings];
    updatedDocTypeMappings.splice(index, 1);
    onChange(updatedDocTypeMappings);
  };

  const handleDocTypeMappingChange = (
    index: number,
    field: keyof DocTypeMapping,
    value: string,
  ) => {
    const updatedDocTypeMappings = [...docTypeMappings];
    updatedDocTypeMappings[index][field] = value;
    onChange(updatedDocTypeMappings);
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Box>
      <AddableList
        sx={{ overflow: "auto", maxHeight: "25vh" }}
        onAddClick={handleAddDocTypeMapping}
        subheaderText="DocType Mappings"
      >
        {docTypeMappings.map((docTypeMapping, index) => (
          <ListItem
            endAction={
              <IconButton
                aria-label="Delete"
                size="sm"
                color="danger"
                onClick={() => handleDeleteDocTypeMapping(index)}
              >
                <Delete />
              </IconButton>
            }
            key={index}
          >
            <Grid
              container
              spacing={1}
              width={"100%"}
              sx={{ paddingRight: "20px" }}
            >
              <Grid xs={6}>
                <Input
                  fullWidth
                  size="sm"
                  placeholder="Map From"
                  value={docTypeMapping.mapFrom || ""}
                  onChange={(event) =>
                    handleDocTypeMappingChange(
                      index,
                      "mapFrom",
                      event.target.value,
                    )
                  }
                  onKeyDown={(event) =>
                    handleKeyPress(
                      event as React.KeyboardEvent<HTMLInputElement>,
                      index,
                    )
                  }
                />
              </Grid>
              <Grid xs={6}>
                <Input
                  fullWidth
                  size="sm"
                  placeholder="Map To"
                  value={docTypeMapping.mapTo || ""}
                  onChange={(event) =>
                    handleDocTypeMappingChange(
                      index,
                      "mapTo",
                      event.target.value,
                    )
                  }
                  onKeyDown={(event) =>
                    handleKeyPress(
                      event as React.KeyboardEvent<HTMLInputElement>,
                      index,
                    )
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </AddableList>
    </Box>
  );
};

export default DocTypeMappingsControl;
