import { Box, FormControl, FormLabel, Grid, Input, Stack, Switch } from "@mui/joy";
import React from "react";
import { getFormattedDate } from "../../helpers/StringFormatters";
import { CaseMigrationStrategy } from "../../models/ClientConfiguration/CaseMigrationStrategy";
import { ClientConfiguration } from "../../models/ClientConfiguration/ClientConfiguration";
import { DocTypeMapping } from "../../models/ClientConfiguration/DocTypeMapping";
import { FirmDocumentFolder } from "../../models/ClientConfiguration/FirmDocumentFolder";
import { FlagType, FlaggingConfiguration } from "../../models/ClientConfiguration/FlaggingConfiguration";
import { ImplementationType } from "../../models/ClientConfiguration/ImplementationType";
import { MigrationStrategy } from "../../models/ClientConfiguration/MigrationStrategy";
import { RootCaseFolder } from "../../models/ClientConfiguration/RootCaseFolder";
import OutlinedDiv from "../common/OutlinedDiv";
import CaseMigrationStrategySelect from "./CaseMigrationStrategySelect";
import CaseNumberListControl from "./CaseNumberListControl";
import DocTypeMappingsControl from "./DocTypeMappingsControl";
import FileExtensionsFilterInput from "./FileExtensionsFilterInput";
import FileExtensionsFlagInput from "./FileExtensionsFlagInput";
import FirmDocumentsFoldersConfigurationControl from "./FirmDocumentsFoldersConfigurationControl";
import FlagTypeSelect from "./FlagTypeSelect";
import MigrationStrategySelect from "./MigrationStrategySelect";
import RootCaseFoldersConfigurationControl from "./RootCaseFoldersConfigurationControl";

interface MigrationConfigurationSectionProps {
  value: ClientConfiguration;
  onChange: (updatedConfig: ClientConfiguration) => void;
}

const MigrationConfigurationSection: React.FC<
  MigrationConfigurationSectionProps
> = ({ value, onChange }) => {
  const handleMigrationStrategyChange = (
    strategy: MigrationStrategy | "",
    property: keyof ClientConfiguration,
  ) => {
    onChange({
      ...value,
      [property]: strategy,
    });
  };

  const handleFlagTypesChange = (enabledFlags: FlaggingConfiguration[]) => {
    onChange({
      ...value,
      flags: enabledFlags,
    });
  };
  
  const handleFileExtensionFlagChange = (flaggedExtensions: string[]) => {
    const existingFlags = value.flags?.filter(f => f.flagType !== FlagType.MsgCaseFile) || []
    onChange({
      ...value,
      flags: flaggedExtensions.length > 0 ? 
        [...existingFlags, {flagType: FlagType.MsgCaseFile, flagProperties: flaggedExtensions.map(extension => {return {key: "extension", value: extension}})}] :
        existingFlags
    });
  };
  const handleCaseStrategySelectChange = (
    caseStrategies: CaseMigrationStrategy[],
  ) => {
    onChange({
      ...value,
      casesMigrationStrategy: caseStrategies,
    });
  };

  const handleCasePriorityListChange = (casePriorityList: string[]) => {
    onChange({
      ...value,
      casePriorityList: casePriorityList,
    });
  };

  const handleCaseSpecifiedListChange = (caseSpecifiedList: string[]) => {
    onChange({
      ...value,
      caseSpecifiedList: caseSpecifiedList,
    });
  };

  const handleFirmDocumentsFoldersChange = (folders: FirmDocumentFolder[]) => {
    onChange({
      ...value,
      firmDocumentFolders: folders,
    });
  };
  
  const handleRootCaseFoldersChange = (folders: RootCaseFolder[]) => {
    onChange({
      ...value,
      rootCaseFolders: folders,
    });
  };
  
  const handleDocTypeMappingsChange = (mappings: DocTypeMapping[]) => {
    onChange({
      ...value,
      docTypeMappings: mappings,
    });
  };
  
  const enabledFileExtensionFlags = value.flags
    ?.filter(f => f.flagType === FlagType.MsgCaseFile)
    .at(0)?.flagProperties
    .filter(prop => prop.key === 'extension').map(prop => prop.value) || [];
  
  const openClosedSettingsVisibility = Array.isArray(value.casesMigrationStrategy)
    ? (value.casesMigrationStrategy.includes(CaseMigrationStrategy.Custom) 
      ? 'block' 
      : 'none')
    : 'none';

  const specifiedCaseNumbersVisibility = Array.isArray(value.casesMigrationStrategy)
    ? (value.casesMigrationStrategy.includes(CaseMigrationStrategy.AllCases) 
      || value.casesMigrationStrategy.includes(CaseMigrationStrategy.BasicPlus) 
        ? 'block' 
        : 'none')
    : 'none';


  return (
    <Grid container spacing={1} sx={{ flexGrow: 1 }}>
      <Grid xs={12} md={6} sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} sx={{ flexGrow: 1 }}>
          <Grid xs={12} md={6} minWidth={"50%"}>
            <OutlinedDiv label="Enabled Discoveries">
              <Stack
                height={"100%"}
                spacing={1}
                sx={{ display: "flex", flexDirection: "column" }}
                justifyContent={"center"}
              >
                <FormControl
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Cases</FormLabel>
                  <Switch
                    checked={value.discoverCases}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        discoverCases: event.target.checked,
                      })
                    }
                  />
                </FormControl>
                <FormControl
                  disabled={value.implementationType != ImplementationType.TrialWorks}
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Intakes</FormLabel>
                  <Switch
                    checked={value.discoverIntakes}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        discoverIntakes: event.target.checked,
                      })
                    }
                  />
                </FormControl>
                <FormControl
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Providers</FormLabel>
                  <Switch
                    checked={value.discoverProviders}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        discoverProviders: event.target.checked,
                      })
                    }
                  />
                </FormControl>
                <FormControl
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Firm Documents</FormLabel>
                  <Switch
                    checked={value.discoverFirmDocuments}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        discoverFirmDocuments: event.target.checked,
                      })
                    }
                  />
                </FormControl>
                <FormControl
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Template Files</FormLabel>
                  <Switch
                    checked={value.discoverTemplateFiles}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        discoverTemplateFiles: event.target.checked,
                      })
                    }
                  />
                </FormControl>
              </Stack>
            </OutlinedDiv>
          </Grid>
          <Grid xs={12} md={6} minWidth={"50%"}>
            <OutlinedDiv label="Enabled Migrations">
              <Stack
                height={"100%"}
                spacing={1}
                sx={{ display: "flex", flexDirection: "column" }}
                justifyContent={"center"}
              >
                <FormControl
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Cases</FormLabel>
                  <Switch
                    checked={value.migrateCases}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        migrateCases: event.target.checked,
                      })
                    }
                  />
                </FormControl>
                <FormControl
                  disabled={value.implementationType != ImplementationType.TrialWorks}
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Intakes</FormLabel>
                  <Switch
                      checked={value.migrateIntakes}
                      onChange={(event) =>
                          onChange({
                            ...value,
                            migrateIntakes: event.target.checked,
                          })
                      }
                  />
                </FormControl>
                <FormControl
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Providers</FormLabel>
                  <Switch
                    checked={value.migrateProviders}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        migrateProviders: event.target.checked,
                      })
                    }
                  />
                </FormControl>
                <FormControl
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Firm Documents</FormLabel>
                  <Switch
                    checked={value.migrateFirmDocuments}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        migrateFirmDocuments: event.target.checked,
                      })
                    }
                  />
                </FormControl>
                <FormControl
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Template Files</FormLabel>
                  <Switch
                    checked={value.migrateTemplateFiles}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        migrateTemplateFiles: event.target.checked,
                      })
                    }
                  />
                </FormControl>
              </Stack>
            </OutlinedDiv>
          </Grid>

          <Grid xs={12} md={6} minWidth={"100%"}>
            <FlagTypeSelect
              value={value.flags || []}
              onChange={handleFlagTypesChange}
            />
          </Grid>
          <Grid xs={12} md={6} minWidth={{xs: "100%", md: "50%"}} maxWidth={{xs: "100%", md: "50%"}}>
            <FileExtensionsFlagInput
              extensionFlags={enabledFileExtensionFlags}
              onChange={handleFileExtensionFlagChange}
            />
          </Grid>
          <Grid xs={12} md={6} minWidth={{xs: "100%", md: "50%"}} maxWidth={{xs: "100%", md: "50%"}}>
            <FileExtensionsFilterInput
              filters={value.fileTypeFilters || []}
              onChange={(newFilters) =>
                onChange({
                  ...value,
                  fileTypeFilters: newFilters,
                })
              }
            />
          </Grid>
          <Grid xs={12} minWidth={'100%'}>
            <OutlinedDiv label="">
            <Stack
                height={"100%"}
                spacing={1}
                sx={{ display: "flex", flexDirection: "column" }}
                justifyContent={"center"}
              >
          <FormControl
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Upload Files Metadata To SharePoint</FormLabel>
                  <Switch
                   checked={value.uploadFilesMetadataToSharepoint}
                   onChange={(event) =>
                     onChange({
                       ...value,
                       uploadFilesMetadataToSharepoint: event.target.checked,
                     })
                   }
                  />
                </FormControl>
                </Stack>
                </OutlinedDiv>
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12} md={6} minWidth={"50%"}>
        <Grid container spacing={1}>
          <Grid xs={12} minWidth={"100%"}>
            <Box>
              <OutlinedDiv label="Cases Migration Strategy">
                <CaseMigrationStrategySelect
                  value={
                    value.casesMigrationStrategy || [
                      CaseMigrationStrategy.AllCases,
                      CaseMigrationStrategy.DateDescending,
                    ]
                  }
                  onChange={handleCaseStrategySelectChange}
                  required
                />

                <Box sx={{ display: openClosedSettingsVisibility }}>
                  <OutlinedDiv label={""}>
                    <Grid container spacing={2}>
                      <Grid xs={1}>
                        <FormControl sx={{ pb: 1, mt: -0.25 }}>
                          <FormLabel>Open</FormLabel>
                          <Box>
                            <Switch
                              sx={{mt: 0.5}}
                              checked={value.migrateOpenedCases}
                              onChange={(event) => {
                                const isChecked = event.target.checked;
                                onChange({
                                  ...value,
                                  migrateOpenedCases: isChecked,
                                  casesFromDay: isChecked ? value.casesFromDay : null,
                                });
                              }}
                            />
                          </Box>
                        </FormControl>
                      </Grid>
                      
                      <Grid xs={11}>
                        <FormControl
                          sx={{ pb: 1, marginTop: -0.25,  ml: 3 }}
                          disabled={!value.migrateOpenedCases}
                        >
                          <FormLabel>Opened After</FormLabel>
                          <Input
                            value={
                              value.casesFromDay
                                ? getFormattedDate(value.casesFromDay, "yyyy-MM-dd")
                                : ""
                            }
                            onChange={(event) =>
                              onChange({
                                ...value,
                                casesFromDay: event.target.value
                                  ? getFormattedDate(event.target.value, "yyyy-MM-dd")
                                  : null,
                              })
                            }
                            size={"sm"}
                            type="date"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
  
                    <Grid container spacing={2} sx={{ mb: -2}}>
                      <Grid xs={1}>
                        <FormControl sx={{ pb: 1, marginTop: -0.25 }}>
                          <FormLabel>Closed</FormLabel>
                          <Box>
                            <Switch 
                              sx={{mt: 0.5}}
                              checked={value.migrateClosedCases}
                              onChange={(event) => {
                                const isChecked = event.target.checked;
                                onChange({
                                  ...value,
                                  migrateClosedCases: isChecked,
                                  casesClosedAfter: isChecked ? value.casesClosedAfter : null,
                                });
                              }}
                            />
                          </Box>
                        </FormControl>
                      </Grid>
                      
                      <Grid xs={11}>
                        <FormControl 
                          sx={{ marginTop: -0.25, ml: 3 }}
                          disabled={!value.migrateClosedCases}
                        >
                          <FormLabel>Closed After</FormLabel>
                          <Input
                            value={
                              value.casesClosedAfter
                                ? getFormattedDate(value.casesClosedAfter, "yyyy-MM-dd")
                                : ""
                            }
                            onChange={(event) =>
                              onChange({
                                ...value,
                                casesClosedAfter: event.target.value
                                  ? getFormattedDate(event.target.value, "yyyy-MM-dd")
                                  : null,
                              })
                            }
                            size={"sm"}
                            type="date"
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </OutlinedDiv>
                </Box>
                
                <Box sx={{ display: specifiedCaseNumbersVisibility }}>
                  <CaseNumberListControl
                    label={"Specified case numbers"}
                    values={value.caseSpecifiedList || []}
                    onChange={handleCaseSpecifiedListChange}
                    isTrialworks={
                      value.implementationType === ImplementationType.TrialWorks
                    }
                  />
                </Box>
                
                <CaseNumberListControl
                  label={"Prioritized case numbers"}
                  values={value.casePriorityList || []}
                  onChange={handleCasePriorityListChange}
                  isTrialworks={
                    value.implementationType === ImplementationType.TrialWorks
                  }
                />
              </OutlinedDiv>
            </Box>
              
          </Grid>
          <Grid xs={12} minWidth={"100%"}>
            <MigrationStrategySelect
              value={
                value.providersMigrationStrategy ||
                MigrationStrategy.DateDescending
              }
              onChange={(value) =>
                handleMigrationStrategyChange(
                  value,
                  "providersMigrationStrategy",
                )
              }
              label="Providers Migration Strategy"
              required
            />
          </Grid>
          <Grid xs={12} minWidth={"100%"}>
            <MigrationStrategySelect
              value={
                value.firmDocumentsMigrationStrategy ||
                MigrationStrategy.DateDescending
              }
              onChange={(value) =>
                handleMigrationStrategyChange(
                  value,
                  "firmDocumentsMigrationStrategy",
                )
              }
              label="Firm Documents Migration Strategy"
              required
            />
          </Grid>
          <Grid xs={12} minWidth={"100%"}>
            <MigrationStrategySelect
              value={
                value.templateFilesMigrationStrategy ||
                MigrationStrategy.DateDescending
              }
              onChange={(value) =>
                handleMigrationStrategyChange(
                  value,
                  "templateFilesMigrationStrategy",
                )
              }
              label="Template Files Migration Strategy"
              required
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} md={6}>
        <FirmDocumentsFoldersConfigurationControl
          folders={value.firmDocumentFolders || []}
          onChange={handleFirmDocumentsFoldersChange}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <RootCaseFoldersConfigurationControl
          folders={value.rootCaseFolders || []}
          onChange={handleRootCaseFoldersChange}
        />
      </Grid>
      {value.implementationType === ImplementationType.TrialWorks ? (
        <Grid xs={12}>
          <DocTypeMappingsControl
            docTypeMappings={value.docTypeMappings || []}
            onChange={handleDocTypeMappingsChange}
          />
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default MigrationConfigurationSection;
