import { ClientInfo } from "../models/ClientInfo";
import useApi from "../services/ApiService";

export class ClientInfoRepository {
  private api = useApi();

  async deleteClient(clientId: string): Promise<void> {
    try {
      return await this.api.delete(`/api/Clients/${clientId}`);
    } catch (error) {
      // Handle error
      throw new Error("Failed to delete client");
    }
  }

  async setActiveConfig(clientId: string, configId: string): Promise<void> {
    const patchClientRequest = {
      clientId: clientId,
      clientDto: {
        configurationId: configId,
      },
    };

    try {
      return await this.api.patch("/api/Clients/", patchClientRequest);
    } catch (error) {
      // Handle error
      throw new Error("Failed to patch client info");
    }
  }
  async getClientInfos(organizationId: string): Promise<ClientInfo[]> {
    try {
      const response = await this.api.get(
        `/api/Clients?OrganizationId=${organizationId}`,
      );
      const clientInfos = response.data as ClientInfo[];
      return clientInfos;
    } catch (error) {
      // Handle error
      throw new Error("Failed to fetch client info");
    }
  }
}

export default ClientInfoRepository;
