export type Order = "asc" | "desc";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  const aValue = String(a[orderBy]).toLowerCase();
  const bValue = String(b[orderBy]).toLowerCase();

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function compareBy<T>(order: Order, property: keyof T) {
  return function (a: T, b: T): number {
    return order === "desc"
      ? descendingComparator(a, b, property)
      : -descendingComparator(a, b, property);
  };
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export { compareBy, descendingComparator, stableSort };
