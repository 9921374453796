import { Chip } from "@mui/joy";
import React from "react";
import { MigrationStatus, MigrationStatusColors, MigrationStatusLabels } from "../../models/MigrationStatus";

type ChipBreakpoint = 'sm' | 'md' | 'lg';

interface MigrationStatusChipProps {
  status: MigrationStatus;
  size?: ChipBreakpoint
}

const MigrationStatusChip: React.FC<MigrationStatusChipProps> = ({ status, size = "sm" }) => {

  return (
    <Chip variant="soft" size={size} color={MigrationStatusColors[status]}>
      {MigrationStatusLabels[status]}
    </Chip>
  );
};

export default MigrationStatusChip;
