import { Box, Button, Typography } from "@mui/joy";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import ClientsTable from "../components/Clients/ClientsTable";
import ConfirmSaveChangesModal from "../components/common/ConfirmSaveChangesModal";
import ClientInfoRepository from "../repositories/ClientInfoRepository";

const ClientsPage = () => {
  const { organizationId } = useParams();
  const [changedConfigs, setChangedConfigs] = useState<
    { clientId: string; newConfigId: string }[]
  >([]);
  const [deleteClientId, setDeleteClientId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteClient = (clientId: string) => {
    setDeleteClientId(clientId);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteClientId(null);
    setIsDeleteModalOpen(false);
  };
  const queryClient = useQueryClient();
  const clientInfoRepository = new ClientInfoRepository();
  const updateClientConfigsMutation = useMutation(
    (updatedConfigs: { clientId: string; newConfigId: string }[]) => {
      const promises = updatedConfigs.map(({ clientId, newConfigId }) =>
        clientInfoRepository.setActiveConfig(clientId, newConfigId),
      );
      return Promise.all(promises);
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["clientInfos", organizationId]),
    },
  );
  const handleConfirmDelete = async () => {
    if (deleteClientId) {
      try {
        await clientInfoRepository.deleteClient(deleteClientId);
        queryClient.invalidateQueries(["clientInfos", organizationId]);
      } catch (error) {
        console.error("Failed to delete client:", error);
      }
    }

    handleCloseDeleteModal();
  };

  const handleConfigChange = (clientId: string, newConfigId: string) => {
    const index = changedConfigs.findIndex(
      (item) => item.clientId === clientId,
    );

    if (index !== -1) {
      setChangedConfigs((prevConfigs) => {
        const updatedConfigs = [...prevConfigs];
        updatedConfigs[index] = { clientId, newConfigId };
        return updatedConfigs;
      });
    } else {
      setChangedConfigs((prevConfigs) => [
        ...prevConfigs,
        { clientId, newConfigId },
      ]);
    }
  };
  const handleSaveChanges = async () => {
    try {
      await updateClientConfigsMutation.mutateAsync(changedConfigs);
      setChangedConfigs([]);
    } catch (error) {
      console.error("Error updating client configurations:", error);
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Clients</Typography>
        <Button
          color="primary"
          size="sm"
          disabled={changedConfigs.length === 0}
          onClick={handleSaveChanges}
        >
          Save Changes
        </Button>
      </Box>
      <ClientsTable
        organizationId={organizationId ?? ""}
        onConfigChange={handleConfigChange}
        onDeleteClient={async (clientId) => {
          await handleDeleteClient(clientId);
        }}
      />
      <ConfirmSaveChangesModal
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onSaveChanges={handleConfirmDelete}
        message={() => "Are you sure you want to delete this client?"}
      />
    </>
  );
};

export default ClientsPage;
