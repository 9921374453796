import { Box, FormControl, FormLabel, Grid, Input } from "@mui/joy";
import React, { useState } from "react";
import { User } from "../../models/User";
import { Organization } from "../../models/Organization";
import GenericMultipleSelect, {
  MultipleSelectOption,
} from "../common/GenericMultipleSelect";

interface UserDetailsSectionProps {
  value: User;
  onChange: (updatedUser: User) => void;
  organizations: Organization[];
}

const UserDetailsSection: React.FC<UserDetailsSectionProps> = ({
  value,
  onChange,
  organizations,
}) => {
  const organizationOptions = organizations.map((org) => ({
    id: org.id,
    label: org.name ?? `NO NAME - ID: ${org.id}`,
  }));

  const alreadyAssigneOrganizationOptions = organizations
    .filter((x) => x.assignedUser.id === value.id)
    .map((org) => ({
      id: org.id,
      label: org.name ?? `NO NAME - ID: ${org.id}`,
    }));

  const [assignedOrganizations, setAssignedOrganizations] = useState<
    MultipleSelectOption[]
  >(alreadyAssigneOrganizationOptions);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      username: event.target.value,
    });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      email: event.target.value,
    });
  };

  const handleOrganizationsChange = (orgIds: string[]) => {
    onChange({
      ...value,
      organizationIds: orgIds,
    });
  };

  return (
    <Box>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid xs={12} md={12}>
          <FormControl>
            <FormLabel>ID</FormLabel>
            <Input
              required
              value={value.id || ""}
              name="id"
              fullWidth
              disabled
            />
          </FormControl>
        </Grid>

        <Grid xs={12} md={12}>
          <FormControl>
            <FormLabel>Username</FormLabel>
            <Input
              onChange={handleUsernameChange}
              value={value.username || ""}
              name="username"
              fullWidth
              required
            />
          </FormControl>
        </Grid>

        <Grid xs={12} md={12}>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              onChange={handleEmailChange}
              value={value.email || ""}
              name="email"
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid xs={12} md={12}>
          <FormControl>
            <FormLabel>Assigned organizations</FormLabel>

            {organizationOptions[0] && (
              <GenericMultipleSelect
                inputValues={assignedOrganizations ?? []}
                options={organizationOptions}
                placeholder="Choose organizations"
                setValues={(selectedOptions) => {
                  handleOrganizationsChange(selectedOptions.map((x) => x.id));
                  setAssignedOrganizations(selectedOptions);
                }}
              ></GenericMultipleSelect>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDetailsSection;
