import CloseIcon from "@mui/icons-material/Close";
import { FormControl, FormLabel, IconButton, Input, Option, Select } from "@mui/joy";
import * as React from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import ServiceVersionsRepository from "../../repositories/ServiceVersionsRepository";
interface ServiceVersionSelectProps {
  value: string;
  onChange: (value: string) => void;
}

const ServiceVersionSelect: React.FC<ServiceVersionSelectProps> = ({
  value,
  onChange,
}) => {
  const serviceVersionRepository = new ServiceVersionsRepository();
  const [version, setVersion] = useState(value);
  const handleClearClick = () => {
    setVersion("");
    onChange("");
  };
  const {
    data: serviceVersions = [],
    isLoading,
    error,
  } = useQuery(
    ["serviceVersions"],
    () => serviceVersionRepository.getServiceVersions(),
    {
      staleTime: 1000 * 60 * 10,
      refetchInterval: 1000 * 60 * 5, // Set the interval for automatic refetching (in milliseconds)
      refetchOnMount: false, // Disable automatic refetching on component mount
      refetchOnWindowFocus: false,
    }, // Disable automatic refetching when the window regains focus
  );

  if (isLoading) {
    return <div>Loading service versions...</div>;
  }

  if (error) {
    return <div>Error: {(error as Error).message}</div>;
  }
  const handleInputChange = (event: any) => {
    const { value } = event.target;
    // Replace any non-numeric and non-dot characters with an empty string
    const formattedValue = value.replace(/[^0-9.]/g, '');
    event.target.value = formattedValue;
  };
  const handleSelectChange = (selectedValue: string) => {
    onChange(selectedValue);
    setVersion(selectedValue);
  };

  return (
    <FormControl>
      <FormLabel id="service-version-label">Service Version</FormLabel>
      {value === "custom" ? 
      <Input 
      autoFocus={true}
      onBlur={(event) => handleSelectChange(event.target.value || "")}
      onChange={handleInputChange}
      endDecorator={
        <IconButton
        size="sm"
        sx={{ visibility: version ? "visible" : "hidden", padding: "0" }}
        onClick={handleClearClick}
      >
        <CloseIcon sx={{ padding: "0", fontSize: "0.8em" }} />
      </IconButton>
      }>
      </Input> : 
            <Select
            sx={{
              "& .MuiSelect-iconOutlined": { display: version ? "none" : "" },
              "&.Mui-focused .MuiIconButton-root": { color: "primary.main" },
            }}
            id="service-version"
            name="serviceVersion"
            value={value}
            endDecorator={
              version ? (
                <IconButton
                  size="sm"
                  sx={{ visibility: version ? "visible" : "hidden", padding: "0" }}
                  onClick={handleClearClick}
                >
                  <CloseIcon sx={{ padding: "0", fontSize: "0.8em" }} />
                </IconButton>
              ) : (
                false
              )
            }
            onChange={(event, value) => handleSelectChange(value || "")}
          >           
            {value &&
              !(
                serviceVersions.findIndex((version) => version.version === value) >
                -1
              ) && (
                <Option key={value} value={value}>
                  {value}
                </Option>
              )}
            {serviceVersions.map((version) => (
              <Option key={version.version} value={version.version}>
                {version.version}
              </Option>
            ))}
            <Option value={"custom"}>
            {"Custom"}
            </Option>
          </Select>
      }
    </FormControl>
  );
};

export default ServiceVersionSelect;
