import { ServiceVersion } from "../models/ClientConfiguration/ServiceVersion";
import useApi from "../services/ApiService";

export class ServiceVersionRepository {
  private api = useApi();

  async getServiceVersions(): Promise<ServiceVersion[]> {
    try {
      const response = await this.api.get("/api/updates/versions/client");
      const clientAppSetups = response.data as ServiceVersion[];
      return clientAppSetups;
    } catch (error) {
      // Handle error
      throw new Error("Failed to fetch service versions");
    }
  }
}

export default ServiceVersionRepository;
