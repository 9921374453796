import React from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Input,
  Skeleton,
  Typography,
} from "@mui/joy";
import { User } from "../../models/User";
import UsersRepository from "../../repositories/UsersRepository";
import UserDetailsSection from "./UserDetailsSection";
import { Organization } from "../../models/Organization";
import { useNavigate } from "react-router-dom";

interface UserDetailsFormProps {
  initUser: User;
  organizations: Organization[];
}

const UserDetailsForm: React.FC<UserDetailsFormProps> = ({
  initUser,
  organizations,
}) => {
  const [user, setUser] = React.useState<User>(initUser);
  const [isUploading, setIsUploading] = React.useState(false);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const usersRepository = new UsersRepository();

  const userMutation = useMutation(
    ["user", user.id],
    async (updatedUser: User) => {
      if (user.organizationIds) {
        await usersRepository.updateUserToOrganizationAssignment(
          user.id,
          user.organizationIds ?? []
        );
      }
      return await usersRepository.updateUser(user.id, updatedUser);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user", user.id]);
        queryClient.invalidateQueries("users");
        queryClient.invalidateQueries("organizations");
        navigate("/users/");
      },
      onSettled: () => {
        setIsUploading(false);
      },
    }
  );

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  return (
    <Skeleton variant="inline" loading={isUploading}>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            my: 1,
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "start", sm: "center" },
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Typography level="h2">
            <Input
              fullWidth
              sx={{ fontSize: "1.9rem" }}
              size="lg"
              variant="plain"
              value={user.username}
              required
            />
          </Typography>
          <Button
            color="primary"
            onClick={async () => {
              userMutation.mutate(user);
              setIsUploading(true);
            }}
            disabled={isUploading}
          >
            {"Submit"}
          </Button>
        </Box>

        <Grid container spacing={2} height={"100%"}>
          <Grid xs={12}>
            <Card variant={"outlined"}>
              <CardContent>
                <Typography level="h4" component="div">
                  Details
                </Typography>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <UserDetailsSection
                  value={user}
                  onChange={(updatedUser) => {
                    setUser(updatedUser);
                  }}
                  organizations={organizations}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid xs={12}></Grid>
        </Grid>
      </form>
    </Skeleton>
  );
};

export default UserDetailsForm;
