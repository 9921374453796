/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import {useNavigate} from "react-router-dom";
import {Order} from "../../helpers/TableHelpers";
import {Case} from "../../models/Case";
import {MigrationStatus, MigrationStatusColors, MigrationStatusLabels} from "../../models/MigrationStatus";
import CasesRepository from "../../repositories/CasesRepository";
import {ClearableSelect} from "../common/ClearableSelect";
import MigrationStatusChip from "../common/MigrationStatusChip";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";
import UpdateCaseFlagModal from "./UpdateCaseFlagModal";
import {MigrationFlagType} from "../../models/Flags/MigrationFlagType";
import GenericSelect from "../common/GenericSelect";
import {migrationFlagStatusSelectOptions, MigrationFlagStatus} from "../../models/Flags/MigrationFlagStatus";
// icons
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from "@mui/icons-material/Search";
import {IconButton, Select, Skeleton} from "@mui/joy";

interface CasesTableProps {
  organizationId: string;
}

const CasesTable: React.FC<CasesTableProps> = ({
    organizationId
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [sort, setSort] = React.useState<string>("status");
  const [statusFilter, setStatusFilter] = React.useState<string | null>(null);
  const [searchForCaseNumber, setSearchForCaseNumber] = useState<string | null>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [cases, setCases] = useState<Case[]>([]);
  const [rows, setRows] = useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [dirtyFlagStatus, setDirtyFlagStatus] = useState<MigrationFlagStatus>()
  const [dirtyFlagId, setDirtyFlagId] = useState<string>("");
  const [dirtyCaseNumber, setDirtyCaseNumber] = useState<string>("");
  const casesRepository = new CasesRepository();
  const navigate = useNavigate();
  const casesQuery = useQuery(
    ["cases", organizationId, searchForCaseNumber, page, rowsPerPage, statusFilter, sort, order],
    async () => {
      const cases = await casesRepository.getCases({
        organizationId: organizationId, 
        caseNumber: searchForCaseNumber ? [searchForCaseNumber] : null,
        descending: order === "desc",
        orderBy: sort,
        status: statusFilter ? [statusFilter] : null,
        pageNumber: page + 1, 
        pageSize: rowsPerPage});
      return cases;
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1, // Refetch data every 2 minutes
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    },
  );
  useEffect(() => {
    if (casesQuery.data?.data) {
      setCases(casesQuery.data?.data);
      setRows(casesQuery.data.paging?.totalCount ?? 0)
    }
  }, [casesQuery]);
  const handleSearch = (newCaseNumber: string) => {
    setSearchForCaseNumber(newCaseNumber ?? null);
  };
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };
  const changeSort = (newSort: string) => {
    setPage(0);
    setSort(newSort);
  };
  const changeOrder = (newOrder: Order) => {
    setPage(0);
    setOrder(newOrder);
  };
  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? rows
      : Math.min(rows, (page + 1) * rowsPerPage);
  };
  
  function labelDisplayedRows({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }
  const { isError, isFetching } = casesQuery;
  
  const renderFilters = () => (
    <React.Fragment>
        <FormControl size="sm">
          <FormLabel>Status</FormLabel>
          <ClearableSelect
            value={statusFilter}
            color={MigrationStatusColors[statusFilter as MigrationStatus]}
            onChange={(newValue) => {
              setPage(0);
              setStatusFilter(newValue);
            }}
            size="sm"
            placeholder="Filter by status"
            slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
          >
            {Object.values(MigrationStatus).filter(v => v !== MigrationStatus.NotFound)
            .map((status) => {
            const migrationStatus = status as MigrationStatus;
            return (
              <Option value={migrationStatus} color={MigrationStatusColors[migrationStatus]}>
                {MigrationStatusLabels[migrationStatus]}
              </Option>
            );
          })}
          </ClearableSelect>
        </FormControl>
    </React.Fragment>
  );
  
  const handleFlagStatusModal = (
    caseNumber: string,
    flagId: string, 
    flagStatus: MigrationFlagStatus, 
    isOpen: boolean
  ) => {
    setIsModalOpen(isOpen);
    setDirtyCaseNumber(caseNumber);
    setDirtyFlagId(flagId);
    setDirtyFlagStatus(flagStatus);
  }
  

  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
          <FormControl sx={{ flex: 1 }} size="sm">
            <FormLabel>Search for case</FormLabel>
            <Input
              size="sm"
              placeholder="Case number"
              startDecorator={<SearchIcon />}
              onBlur={(event) => handleSearch(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  const target = event.target as HTMLInputElement;
                  target.blur();
                }
              }}
            />
          </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="CasesTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            stickyFooter
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "5%",
                    minWidth: 48,
                    textAlign: "center",
                    padding: "12px 6px",
                  }}
                >
                </th>
  
                <th
                  style={{
                    width: "15%",
                    padding: "12px 6px",
                  }}
                >
                  <SortableTableColumnHeader
                    label={"Case Number"}
                    sortProperty={"caseNumber"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
  
                <th
                  style={{width: "15%", padding: "12px 6px"}}
                >
                  <SortableTableColumnHeader
                    label={"Status"}
                    sortProperty={"status"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
  
                <th
                  style={{width: "15%", padding: "12px 6px"}}
                >
                  Progress
                </th>
  
                <th
                  style={{width: "15%", padding: "12px 6px"}}
                >
                  <SortableTableColumnHeader
                    label={"Files Count"}
                    sortProperty={"totalNumberOfFiles"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
  
                <th
                  style={{width: "15%", padding: "12px 6px"}}
                >
                  <SortableTableColumnHeader
                    label={"Size (MB)"}
                    sortProperty={"totalFilesSize"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
  
                <th
                  style={{width: "20%", padding: "12px 6px"}}
                >
                  Flag Status
                </th>
  
                <th
                  style={{
                    width: "5%",
                    minWidth: 48,
                    textAlign: "center",
                    padding: "12px 6px",
                  }}
                >
                </th>
              </tr>
            </thead>
            
            <tbody>
              {cases.map((caseEntity) => {
                const migratedPercentage = caseEntity.statistics.totalNumberOfFiles > 0
                  ? (caseEntity.statistics.filesMigrated / caseEntity.statistics.totalNumberOfFiles) * 100 
                    : -1;
  
                  const moreThan10kFlag = caseEntity.flags
                    .filter(f => f.flagType === MigrationFlagType.CaseWithMoreThan10kFiles)
                    .at(0);
  
                  const migrationFlagStatusSelectOption = migrationFlagStatusSelectOptions
                    .find(option => option.id === moreThan10kFlag?.flagStatus)
                    || migrationFlagStatusSelectOptions[0];
                  
                  return (
                    <tr
                      key={caseEntity.id}
                      onDoubleClick={() =>
                        navigate(`/organization/${organizationId}/case/${caseEntity.id}`)
                      }
                    >
                      <td style={{textAlign: "center", width: 120}}></td>
                      <td>
                        <Typography level="body-sm">
                          {caseEntity.caseNumber}
                        </Typography>
                      </td>
                      <td>
                        <MigrationStatusChip status={caseEntity.status}/>
                      </td>
                      <td>
                        {caseEntity.statistics.totalNumberOfFiles > 0 ? (
                          <Chip
                            sx={{textAlign: "center"}}
                            variant="soft"
                            size="sm"
                            color={
                              migratedPercentage > 99.99
                                ? "success"
                                : "primary"
                            }
                          >
                            {(((migratedPercentage % 1) > 0.99) || ((migratedPercentage % 1) < 0.01))
                              ? `${migratedPercentage.toFixed(
                                0,
                              )}%`
                              : `${migratedPercentage.toFixed(
                                2,
                              )}%`}
                          </Chip>
                        ) : (
                          <Chip variant="soft" size="sm" color="danger">
                            No files
                          </Chip>
                        )}
                      </td>
                      <td>
                        <Chip
                          variant="soft"
                          size="sm"
                          color="neutral"
                        >
                          {caseEntity.statistics.totalNumberOfFiles ?? 0}
                        </Chip>
                      </td>
                      <td>
                        <Chip
                          variant="soft"
                          size="sm"
                          color="neutral"
                        >
                          {(caseEntity.statistics.filesSizeInMb).toFixed(2)}
                        </Chip>
                      </td>
                      <td>
                        {
                          moreThan10kFlag
                            ? <GenericSelect
                              size="sm"
                              options={migrationFlagStatusSelectOptions}
                              placeholder="Select status"
                              inputValue={migrationFlagStatusSelectOption}
                              setValue={
                                (value) => handleFlagStatusModal(caseEntity.caseNumber, moreThan10kFlag.id, value.id, true)
                              }
                            />
                            : <></>
                        }
                      </td>
                      <td style={{textAlign: "center", width: 120}}></td>
                    </tr>
                  );
              })}
            </tbody>
            
            <tfoot>
              <tr>
                <td colSpan={9}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <FormControl orientation="horizontal" size="sm">
                      <FormLabel>Rows per page:</FormLabel>
                      <Select onChange={handleChangeRowsPerPage} value={rowsPerPage}>
                      <Option value={25}>25</Option>
                      <Option value={50}>50</Option>
                      <Option value={100}>100</Option>
                      </Select>
                    </FormControl>
                    
                    <Typography textAlign="center" sx={{ minWidth: 80 }}>
                      {labelDisplayedRows({
                        from: rows === 0 ? 0 : page * rowsPerPage + 1,
                        to: getLabelDisplayedRowsTo(),
                        count: rows === -1 ? -1 : rows,
                      })}
                    </Typography>
                    
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={page === 0}
                        onClick={() => handleChangePage(page - 1)}
                        sx={{ bgcolor: 'background.surface' }}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={
                          rows !== -1
                            ? page >= Math.ceil(rows / rowsPerPage) - 1
                            : false
                        }
                        onClick={() => handleChangePage(page + 1)}
                        sx={{ bgcolor: 'background.surface' }}
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </td>
              </tr>
            </tfoot>
            
          </Table>
        </Skeleton>
      </Sheet>
      
      {/* Flag Modal */}
      {dirtyFlagStatus 
        ? <UpdateCaseFlagModal
            open={isModalOpen}
            flagStatus={dirtyFlagStatus}
            caseNumber={dirtyCaseNumber}
            onClose={() => {
                handleFlagStatusModal("", "", MigrationFlagStatus.UnResolved, false)
            }}
            onConfirm={async () => {
              await casesRepository.updateCaseFlag(dirtyFlagId, dirtyFlagStatus);
              await casesQuery.refetch();
              handleFlagStatusModal("", "", MigrationFlagStatus.UnResolved, false);
            }}
          />
        : <></>
      }
      
    </React.Fragment>
  );
};

export default CasesTable;
