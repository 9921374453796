import { FormControl, FormLabel, Option, Select } from "@mui/joy";
import * as React from "react";
import { ImplementationType } from "../../models/ClientConfiguration/ImplementationType";

interface ImplementationTypeSelectProps {
  value: ImplementationType | undefined;
  onChange: (value: ImplementationType | undefined) => void;
  required: boolean;
}

const ImplementationTypeSelect: React.FC<ImplementationTypeSelectProps> = ({
  value,
  onChange,
  required,
}) => {
  return (
    <FormControl>
      <FormLabel id="implementation-type-label">Implementation Type</FormLabel>
      <Select
        required={required}
        id="implementation-type"
        name="implementationType"
        value={value || ""}
        onChange={(event, value) => onChange(value as ImplementationType)}
      >
        <Option value={ImplementationType.AzureBlob}>Azure Blob</Option>
        <Option value={ImplementationType.Needles4}>Needles 4 - Sybase</Option>
        <Option value={ImplementationType.Needles5SQL}>Needles 5 - SQL</Option>
        <Option value={ImplementationType.Needles5Sybase}>
          Needles 5 - Sybase
        </Option>
        <Option value={ImplementationType.TrialWorks}>Trialworks</Option>
      </Select>
    </FormControl>
  );
};

export default ImplementationTypeSelect;
