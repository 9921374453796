import { Stack } from "@mui/joy";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import * as React from "react";

interface ConfirmSaveChangesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSaveChanges: () => void;
  message: () => string;
}

const ConfirmSaveChangesModal: React.FC<ConfirmSaveChangesModalProps> = ({
  isOpen,
  onClose,
  onSaveChanges,
  message,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalDialog
        aria-labelledby="confirm-modal"
        layout="center"
        sx={{ minWidth: "25%", display: "flex" }}
      >
        <Typography id="confirm-modal" level="h3" textAlign={"center"}>
          {message()}
        </Typography>
        <Stack direction="row" width={"100%"} justifyContent={"space-evenly"}>
          <Button variant="outlined" color="primary" onClick={onSaveChanges}>
            Yes
          </Button>
          <Button variant="outlined" color="danger" onClick={onClose}>
            No
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};

export default ConfirmSaveChangesModal;
