import { Button, ToggleButtonGroup } from "@mui/joy";
import React from "react";
import {
  MigrationStrategy,
  MigrationStrategyLabels,
} from "../../models/ClientConfiguration/MigrationStrategy";
import OutlinedDiv from "../common/OutlinedDiv";

interface MigrationStrategySelectProps {
  value: MigrationStrategy | "";
  onChange: (value: MigrationStrategy | "") => void;
  label: string;
  required: boolean;
}

const MigrationStrategySelect: React.FC<MigrationStrategySelectProps> = ({
  value,
  onChange,
  label,
  required = false,
}) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    selectedValue: MigrationStrategy | "",
  ) => {
    onChange(selectedValue);
  };

  return (
    <OutlinedDiv label={label}>
      <ToggleButtonGroup
        sx={{ width: "100%" }}
        value={value}
        onChange={(event, newValue) => {
          handleChange(event, newValue as MigrationStrategy);
        }}
      >
        {Object.values(MigrationStrategy).map((strategy) => (
          <Button fullWidth key={strategy} value={strategy}>
            {MigrationStrategyLabels[strategy]}
          </Button>
        ))}
      </ToggleButtonGroup>
    </OutlinedDiv>
  );
};

export default MigrationStrategySelect;
