import { Divider, FormControl, FormLabel, Grid, Input } from "@mui/joy";
import React from "react";
import { ClientConfiguration } from "../../models/ClientConfiguration/ClientConfiguration";
import { DriveMapping } from "../../models/ClientConfiguration/DriveMapping";
import { ImplementationType } from "../../models/ClientConfiguration/ImplementationType";
import { RuntimeScheduleConfiguration } from "../../models/ClientConfiguration/RuntimeScheduleConfiguration";
import DriveMappingsControls from "./DriveMappingsControl";
import ImplementationTypeSelect from "./ImplementationTypeSelect";
import LoggingVerbositySelect from "./LoggingVerbositySelect";
import RuntimeScheduleControl from "./RuntimeScheduleControl";
import ServiceVersionSelect from "./ServiceVersionSelect";

interface ClientSettingsSectionProps {
  value: ClientConfiguration;
  onChange: (updatedConfig: ClientConfiguration) => void;
}

const ClientSettingsSection: React.FC<ClientSettingsSectionProps> = ({
  value,
  onChange,
}) => {
  
  const defaultDbConnectionStrings: {[key in ImplementationType]: string} = {
    [ImplementationType.Needles4]: "DSN=Needles",
    [ImplementationType.Needles5Sybase]: "DSN=Needles",
    [ImplementationType.Needles5SQL]: "Server=;Database=Needles;User id=dba;Password=sql;",
    [ImplementationType.TrialWorks]: "Data Source=localhost;Initial Catalog=TrialWorks;Integrated Security=True;",
    [ImplementationType.AzureBlob]: "",

  };

  const handleServiceVersionChange = (version: string) => {
    onChange({
      ...value,
      serviceVersion: version,
    });
  };
  
  const handleMaxConcurrentUploadsChange = (maxUploads: string) => {
    const parsedMaxUploads = maxUploads ? parseInt(maxUploads) : null;
    onChange({
      ...value,
      maxConcurrentUploads: (parsedMaxUploads ?? 1) < 1 ? 1 : (parsedMaxUploads ?? 0) > 64 ? 64 : parsedMaxUploads
    });
  };

  const handleMaxConcurrentDiscoveriesChange = (maxDiscoveries: string) => {
    const parsedMaxDiscoveries = maxDiscoveries ? parseInt(maxDiscoveries) : null;
    onChange({
      ...value,
      maxConcurrentDiscoveries: (parsedMaxDiscoveries ?? 1) < 1 ? 1 : (parsedMaxDiscoveries ?? 0) > 1024 ? 1024 : parsedMaxDiscoveries
    });
  };

  const handleLoggingVerbosityChange = (sendDebugLogs: boolean) => {
    onChange({
      ...value,
      sendDebugLogs: sendDebugLogs,
    });
  };

  const handleDriveMappingsChange = (driveMappings: DriveMapping[]) => {
    onChange({
      ...value,
      driveMappings: driveMappings,
    });
  };

  const handleRuntimeSchedulesChange = (
    runtimeSchedules: RuntimeScheduleConfiguration[],
  ) => {
    onChange({
      ...value,
      runtimeSchedules: runtimeSchedules,
    });
  };

  const handleImplementationTypeChange = (
    implementationType: ImplementationType | undefined,
  ) => {
    onChange({
      ...value,
      implementationType: implementationType,
      dbConnectionString: implementationType ? defaultDbConnectionStrings[implementationType] : ""
    });
  };

  const handleDbConnectionStringChange = (
    newConnectionString: string,
  ) => {
    onChange({
      ...value,
      dbConnectionString: newConnectionString,
    });
  };

  return (
    <Grid container spacing={2} height={"100%"} width={"100%"}>
      <Grid xs={12}>
        <Grid container spacing={1} flex={1}>
          <Grid xs={12} md={3} minWidth={`${(100 / 12) * 3}%`}>
            <ImplementationTypeSelect
              value={value.implementationType || undefined}
              onChange={handleImplementationTypeChange}
              required
            />
          </Grid>
          <Grid xs={12} md={9} minWidth={`${(100 / 12) * 9}%`}>
            <FormControl>
              <FormLabel>DB Connection String</FormLabel>
              <Input
                onChange={(event) => handleDbConnectionStringChange(event.target.value)}
                value={value.dbConnectionString || ""}
                fullWidth
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Grid container spacing={1}>
          <Grid xs={6} minWidth={"50%"}>
          <FormControl>
              <FormLabel>Maximum Number Of Concurrent File Uploads</FormLabel>
              <Input
                type="number"
                onChange={(event) => handleMaxConcurrentUploadsChange(event.target.value)}
                value={value.maxConcurrentUploads === null ? "" : value.maxConcurrentUploads}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid xs={6} minWidth={"50%"}>
          <FormControl>
              <FormLabel>Maximum Number Of Concurrent Discoveries</FormLabel>
              <Input
                type="number"
                onChange={(event) => handleMaxConcurrentDiscoveriesChange(event.target.value)}
                value={value.maxConcurrentDiscoveries === null ? "" : value.maxConcurrentDiscoveries}
                fullWidth
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Grid container spacing={1}>
          <Grid xs={6} minWidth={"50%"}>
            <ServiceVersionSelect
              value={value.serviceVersion || ""}
              onChange={handleServiceVersionChange}
            />
          </Grid>
          <Grid xs={6} minWidth={"50%"}>
            <LoggingVerbositySelect
              value={value.sendDebugLogs}
              onChange={handleLoggingVerbosityChange}
            />
          </Grid>
        </Grid>
        <Divider sx={{ marginY: 2 }} />
      </Grid>
      <Grid xs={12} minWidth={"100%"}>
        <DriveMappingsControls
          driveMappings={value.driveMappings || []}
          onChange={handleDriveMappingsChange}
        />
      </Grid>
      <Grid xs={12} minWidth={"100%"}>
        <RuntimeScheduleControl
          runtimeSchedules={value.runtimeSchedules || []}
          onChange={handleRuntimeSchedulesChange}
        />
      </Grid>
    </Grid>
  );
};

export default ClientSettingsSection;
