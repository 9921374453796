import { Sheet, Typography, styled } from "@mui/joy";
import React from "react";

interface OutlinedDivProps {
  label: string;
  children: React.ReactNode;
}

const StyledPaper = styled(Sheet)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: "16px",
  position: "relative",
  flexGrow: 1,
  width: "100%",
  height: "100%",
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: "-12px",
  left: "12px",
  padding: "0 4px",
  color: theme.palette.text.secondary,
  zIndex: 1, // Ensure label is above content
  "&::before": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.surface,
    height: "54%", // Half of the label height
    width: "100%",
    zIndex: -1, // Behind the label content
  },
}));

const OutlinedDiv: React.FC<OutlinedDivProps> = ({ label, children }) => {
  return (
    <StyledPaper>
      <StyledLabel>
        <Typography level="title-md">{label}</Typography>
      </StyledLabel>
      {children}
    </StyledPaper>
  );
};

export default OutlinedDiv;
