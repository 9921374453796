export enum RuntimeScheduleType {
  Daily = 1,
  WorkDays = 1 << 1,
  Weekends = 1 << 2,
  Monday = 1 << 3,
  Tuesday = 1 << 4,
  Wednesday = 1 << 5,
  Thursday = 1 << 6,
  Friday = 1 << 7,
  Saturday = 1 << 8,
  Sunday = 1 << 9,
}

export const RuntimeScheduleTypeLabels: Record<RuntimeScheduleType, string> = {
  [RuntimeScheduleType.Daily]: "Daily",
  [RuntimeScheduleType.WorkDays]: "Workdays",
  [RuntimeScheduleType.Weekends]: "Weekends",
  [RuntimeScheduleType.Monday]: "Monday",
  [RuntimeScheduleType.Tuesday]: "Tuesday",
  [RuntimeScheduleType.Wednesday]: "Wednesday",
  [RuntimeScheduleType.Thursday]: "Thursday",
  [RuntimeScheduleType.Friday]: "Friday",
  [RuntimeScheduleType.Saturday]: "Saturday",
  [RuntimeScheduleType.Sunday]: "Sunday",
};

export const deserializeRuntimeScheduleTypes = (
  flags: number,
): RuntimeScheduleType[] => {
  const scheduleTypes: RuntimeScheduleType[] = [];

  if (flags & RuntimeScheduleType.Daily) {
    scheduleTypes.push(RuntimeScheduleType.Daily);
  }
  if (flags & RuntimeScheduleType.WorkDays) {
    scheduleTypes.push(RuntimeScheduleType.WorkDays);
  }
  if (flags & RuntimeScheduleType.Weekends) {
    scheduleTypes.push(RuntimeScheduleType.Weekends);
  }
  if (flags & RuntimeScheduleType.Monday) {
    scheduleTypes.push(RuntimeScheduleType.Monday);
  }
  if (flags & RuntimeScheduleType.Tuesday) {
    scheduleTypes.push(RuntimeScheduleType.Tuesday);
  }
  if (flags & RuntimeScheduleType.Wednesday) {
    scheduleTypes.push(RuntimeScheduleType.Wednesday);
  }
  if (flags & RuntimeScheduleType.Thursday) {
    scheduleTypes.push(RuntimeScheduleType.Thursday);
  }
  if (flags & RuntimeScheduleType.Friday) {
    scheduleTypes.push(RuntimeScheduleType.Friday);
  }
  if (flags & RuntimeScheduleType.Saturday) {
    scheduleTypes.push(RuntimeScheduleType.Saturday);
  }
  if (flags & RuntimeScheduleType.Sunday) {
    scheduleTypes.push(RuntimeScheduleType.Sunday);
  }

  return scheduleTypes;
};

export const serializeRuntimeScheduleTypes = (
  scheduleTypes: RuntimeScheduleType[],
): number => {
  let flags = 0;

  for (const scheduleType of scheduleTypes) {
    flags |= scheduleType;
  }

  return flags;
};
