import { FormControl, FormLabel, Stack } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import React, { useState } from "react";

interface CreateUserModalProps {
  open: boolean;
  onClose: () => void;
  onCreateUser: (username: string, email: string, password: string) => void;
}

const CreateUserModal: React.FC<CreateUserModalProps> = ({
  open,
  onClose,
  onCreateUser,
}) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const usernameError = username === "";
  const emailError = email === "";
  const passwordError = password === "";

  const clearFields = () => {
    setUsername("");
    setEmail("");
    setPassword("");
  };

  const handleCreateClick = async () => {
    onCreateUser(username, email, password);
    onClose();
    clearFields();
  };

  const handleClose = () => {
    onClose();
  };

  const isCreateButtonDisabled =
    username.trim() === "" || email.trim() === "" || password.trim() === "";

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const usernameValue = event.target.value;
    setUsername(usernameValue);
  };

  const handleMailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordValue = event.target.value;
    setPassword(passwordValue);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          minWidth: 300,
        }}
      >
        <FormControl>
          <Stack spacing={2} width={"25vW"}>
            <FormControl>
              <FormLabel>Username</FormLabel>
              <Input
                onChange={handleNameChange}
                error={usernameError}
                value={username}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                onChange={handleMailChange}
                error={emailError}
                value={email}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input
                onChange={handlePasswordChange}
                error={passwordError}
                value={password}
              />
            </FormControl>
          </Stack>
        </FormControl>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="outlined"
            color="danger"
            onClick={handleClose}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button onClick={handleCreateClick} disabled={isCreateButtonDisabled}>
            Create
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateUserModal;
