import { Box, Button, Typography } from "@mui/joy";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import CreateOrganizationModal from "../components/Organizations/CreateOrganizationModal";
import OrganizationCreatedModal from "../components/Organizations/OrganizationCreatedModal";
import OrganizationsTable from "../components/Organizations/OrganizationsTable";
import { OrganizationWithSecret } from "../models/Organization";
import OrganizationsRepository from "../repositories/OrganizationsRepository";
import ConfirmSaveChangesModal from "../components/common/ConfirmSaveChangesModal";

interface CreateOrganization {
  name: string;
  intactId: string;
  secret: string;
}

const OrganizationsPage = () => {
  const [createdOrganization, setCreatedOrganization] =
    useState<OrganizationWithSecret | null>(null);
  const [createOrganizationModalOpened, setCreateOrganizationModalOpened] =
    useState<boolean>(false);
  const [saveChangesModalOpened, setSaveChangesModalOpened] =
    useState<boolean>(false);
  const [deleteOrganizationId, setDeleteOrganizationId] = 
    useState<string | null>(null);
  
  const organizationsRepository = new OrganizationsRepository();
  const navigation = useNavigate();
  const queryClient = useQueryClient();
  
  const createOrganizationMutation = useMutation(
    ["organizations"],
    async (newOrganization: CreateOrganization) => {
      const newOrg = await organizationsRepository.createOrganization(
        newOrganization.name,
        newOrganization.intactId,
        newOrganization.secret,
      );
      return newOrg;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("organizations"); // Invalidate the query to trigger a refetch
      },
    },
  );

  const handleConfirmDelete = async () => {
    if (deleteOrganizationId) {
      try {
        await organizationsRepository.deleteOrganization(deleteOrganizationId);
        await queryClient.invalidateQueries("organizations");
      } catch (error) {
        console.error("Failed to delete organization:", error);
      }
    }
    setDeleteOrganizationId(null);
    setSaveChangesModalOpened(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Organizations</Typography>
        <Button
          color="primary"
          size="sm"
          onClick={() => setCreateOrganizationModalOpened(true)}
        >
          Create Organization
        </Button>
      </Box>
      
      <OrganizationsTable
        onDeleteOrganization={function (organizationId: string): void {
          setSaveChangesModalOpened(true);
          setDeleteOrganizationId(organizationId);
        }}
      />
      
      <CreateOrganizationModal
        open={createOrganizationModalOpened}
        onClose={function (): void {
          setCreateOrganizationModalOpened(false);
        }}
        onCreateOrganization={async function (
          name: string,
          intactId: string,
          secret: string,
        ): Promise<void> {
          const organization = await createOrganizationMutation.mutateAsync({
            name: name,
            intactId: intactId,
            secret: secret,
          });
          setCreatedOrganization(organization);
        }}
      />
      
      <OrganizationCreatedModal
        open={createdOrganization !== null}
        onClose={function (): void {
          setCreatedOrganization(null);
        }}
        organization={createdOrganization}
        onGoToOrganization={function (): void {
          navigation(`/organization/${createdOrganization?.id}`);
        }}
      />

      <ConfirmSaveChangesModal
        isOpen={saveChangesModalOpened}
        onClose={function (): void {
          setDeleteOrganizationId(null);
          setSaveChangesModalOpened(false);
        }}
        onSaveChanges={async function (): Promise<void> {
          if (deleteOrganizationId) {
            await handleConfirmDelete();
            return;
          }
        }}
        message={() => {
          return "Are you sure you want to delete this organization?";
        }}
      />
    </>
  );
};

export default OrganizationsPage;
