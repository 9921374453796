import {SelectOption} from "../../components/common/GenericSelect";

export enum MigrationFlagStatus {
  UnResolved = "unResolved",
  Resolved = "resolved",
  Acknowledged = "acknowledged",
}

export const migrationFlagStatusSelectOptions: SelectOption<MigrationFlagStatus>[] = [
  { id: MigrationFlagStatus.UnResolved, label: "Migration Blocked", color: "danger" },
  { id: MigrationFlagStatus.Resolved, label: "Migration Allowed", color: "lime" },
  { id: MigrationFlagStatus.Acknowledged, label: "Flag Acknowledged", color: "yellow" },
];