import { Box, Chip, ChipDelete, FormControl, FormLabel, Input, Tooltip, IconButton, Typography } from "@mui/joy";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from "react";

interface CaseNumberListControlProps {
  label: string;
  values: string[];
  isTrialworks: boolean;
  onChange: (values: string[]) => void;
}

const CaseNumberListControl: React.FC<CaseNumberListControlProps> = ({
  label,
  values,
  isTrialworks,
  onChange,
}) => {
  const handleAddValues = (value: string) => {
    const trimmedValues = value
      .split(/[,\s]+/)
      .map((v) => v.trim())
      .filter((v) => v !== "");

    const newValues = trimmedValues.map((trimmedValue) => {
      let newValue = trimmedValue;
      if (/^tw-(\d+-?)+\d+$/i.test(trimmedValue) || /^(\d+-)?\d+$/.test(trimmedValue)) {
        if (isTrialworks && /^(\d+-)?\d+$/.test(trimmedValue)) {
          newValue = `TW-${trimmedValue}`;
        }
        return newValue;
      } else {
        return null;
      }
    }).filter((v): v is string => v !== null);

    if (newValues.length > 0) {
      onChange(removeDuplicates([...values, ...newValues]));
    }
  };

  const removeDuplicates = (caseNumbers: string[]) => {
    const uniqueCaseNumbers = Array.from(new Set(caseNumbers));
    return uniqueCaseNumbers;
  };

  return (
    <FormControl sx={{ py: 1, marginTop: -0.25 }}>
      <FormLabel sx={{ display: "flex", alignItems: "center" }}>
        {label}
        <Tooltip
           arrow 
           placement="right-start"
           title={
             <Box
               sx={{
                 display: 'flex',
                 flexDirection: 'column',
                 maxWidth: 280,
                 justifyContent: 'center',
               }}
             >
               <Typography
                 fontSize="sm"
                 textColor="white"
               >
                 Accepts numbers typed individually or list of numbers separated by a comma or space
               </Typography>
             </Box>
           }
        >
          <HelpOutlineIcon sx={{ ml: 0.5, fontSize: '18px' }} />
        </Tooltip>
      </FormLabel>
      <Input
        size={'sm'}
        fullWidth
        onBlur={(e) => {
          e.preventDefault();
          const value = e.target.value as string;
          e.target.value = "";
          if (value) {
            handleAddValues(value);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.code === "Space") {
            e.preventDefault();
            const event = e as any;
            const value = event.target.value as string;
            event.target.value = "";
            if (value) {
              handleAddValues(value);
            }
          }
        }}
        startDecorator={
          <Box flex={"grow"}>
            {values.map((value) => (
              <Chip
                key={`case-${value}`}
                size="sm"
                variant="soft"
                color="primary"
                endDecorator={
                  <ChipDelete
                    onDelete={(e) => {
                      e.preventDefault();
                      onChange(values.filter((v) => v !== value));
                    }}
                  />
                }
              >
                {value}
              </Chip>
            ))}
          </Box>
        }
      />
    </FormControl>
  );
};

export default CaseNumberListControl;
