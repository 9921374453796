import { Box, Chip, FormControl, FormLabel, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import CaseFilesTable from "../components/Case/CaseFilesTable";
import UpdateCaseFlagModal from "../components/Cases/UpdateCaseFlagModal";
import MigrationStatusChip from "../components/common/MigrationStatusChip";
import { CaseWithStatistics } from "../models/Case";
import { MigrationStatus, MigrationStatusColors } from "../models/MigrationStatus";
import CasesRepository from "../repositories/CasesRepository";
import {MigrationFlagType} from "../models/Flags/MigrationFlagType";
import GenericSelect from "../components/common/GenericSelect";
import {MigrationFlagStatus, migrationFlagStatusSelectOptions} from "../models/Flags/MigrationFlagStatus";
import React from "react";

const CasePage = () => {
  const { organizationId, caseId } = useParams();
  const casesRepository = new CasesRepository();
  const [caseEntity, setCase] = useState<CaseWithStatistics | undefined>(undefined);
  const [dirtyFlagStatus, setDirtyFlagStatus] = useState<MigrationFlagStatus>()
  const [dirtyFlagId, setDirtyFlagId] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const queryClient = useQueryClient();
  const caseQuery = useQuery(
    ["case", caseId],
    async () => {
      return await casesRepository.getCase(caseId ?? "");
    },
    {
      cacheTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: true,
    },
  );

  useEffect(() => {
    if (caseQuery.data) {
      setCase(caseQuery.data);
    }
  }, [caseQuery.data, caseId]);
  
  const { isLoading, isError } = caseQuery;
  if (isLoading || isError || !!!caseEntity) {
    return <></>;
  }

  const handleFlagStatusModal = (
    flagId: string,
    flagStatus: MigrationFlagStatus,
    isOpen: boolean
  ) => {
    setIsModalOpen(isOpen);
    setDirtyFlagId(flagId);
    setDirtyFlagStatus(flagStatus);
  }
  
  const moreThan10kFlag = caseEntity.flags.filter(f => f.flagType === MigrationFlagType.CaseWithMoreThan10kFiles).at(0);
  const migrationFlagStatusSelectOption = migrationFlagStatusSelectOptions.find(option => option.id === moreThan10kFlag?.flagStatus) || migrationFlagStatusSelectOptions[0];
  const migratedPercentage = caseEntity.statistics.totalNumberOfFiles > 0 && caseEntity.statistics.filesSizeInMb > 0 ? (caseEntity.statistics.filesMigrated / caseEntity.statistics.totalNumberOfFiles) * 100 : 0;
  
  return (
    <>
      <Box
        sx={{
          width:"100%",
          my: 1,
          gap: 1,
          flexWrap: "wrap",
  
        }}>
          <Stack>
            <Typography level="h2">Case {caseEntity.caseNumber} <MigrationStatusChip status={caseEntity.status} size={"md"} /></Typography>    
            <Stack 
            width="100%"
            flex={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}>
            <Stack flex={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}>
            <FormControl size="sm" orientation="horizontal">
                <FormLabel>Not Started</FormLabel>
                <Chip
                variant="soft"
                size="sm"
                color={MigrationStatusColors[MigrationStatus.NotStarted]}
              >
                {caseEntity.statistics.totalNumberOfFiles - (caseEntity.statistics.filesMigrated + caseEntity.statistics.filesFlagged + caseEntity.statistics.filesInterrupted + caseEntity.statistics.filesNotFound)}              
                </Chip>
              </FormControl>
            <FormControl size="sm" orientation="horizontal">
                <FormLabel>Migrated</FormLabel>
                <Chip
                variant="soft"
                size="sm"
                color={MigrationStatusColors[MigrationStatus.Finished]}
              >
                {caseEntity.statistics.filesMigrated}              
                </Chip>
              </FormControl>
            <FormControl size="sm" orientation="horizontal">
                <FormLabel>Interrupted</FormLabel>
                <Chip
                variant="soft"
                size="sm"
                color={MigrationStatusColors[MigrationStatus.Interrupted]}
              >
                {caseEntity.statistics.filesInterrupted}              
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Not Found</FormLabel>
                <Chip
                variant="soft"
                size="sm"
                color={MigrationStatusColors[MigrationStatus.NotFound]}
              >
                {caseEntity.statistics.filesNotFound}              
                </Chip>
              </FormControl>
            <FormControl size="sm" orientation="horizontal">
                <FormLabel>Flagged</FormLabel>
                <Chip
                variant="soft"
                size="sm"
                color={MigrationStatusColors[MigrationStatus.Flagged]}
              >
                {caseEntity.statistics.filesFlagged}              
                </Chip>
              </FormControl>
            
            <FormControl size="sm" orientation="horizontal">
                <FormLabel>Files Count</FormLabel>
                <Chip
                variant="soft"
                size="sm"
                color="neutral"
              >
                {caseEntity.statistics.totalNumberOfFiles}              
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Size (MB)</FormLabel>
                <Chip
                variant="soft"
                size="sm"
                color="primary"
              >
                {caseEntity.statistics.filesSizeInMb.toFixed(2)}              
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Progress</FormLabel>
                <Chip
                  sx={{ textAlign: "center" }}
                  variant="soft"
                  size="sm"
                  color={
                    migratedPercentage > 99.99
                      ? "success"
                      : "primary"
                  }
                >
                  {(((migratedPercentage % 1) > 0.99) || ((migratedPercentage % 1) < 0.01))
                    ? `${migratedPercentage.toFixed(
                        0,
                      )}%`
                    : `${migratedPercentage.toFixed(
                        2,
                      )}%`}
                </Chip>
              </FormControl>
              </Stack>

              {
                moreThan10kFlag
                  ? <GenericSelect
                    width={"260px"}
                    size="md"
                    options={migrationFlagStatusSelectOptions}
                    placeholder="Select status"
                    inputValue={migrationFlagStatusSelectOption}
                    setValue={
                      (value) => handleFlagStatusModal(moreThan10kFlag.id, value.id, true)
                    }
                  />
                  : <></>
              }
            </Stack>
          </Stack>
      </Box>

      <CaseFilesTable caseId={caseId ?? ""} />

      {/* Flag Modal */}
      {dirtyFlagStatus && moreThan10kFlag
        ? <UpdateCaseFlagModal
          open={isModalOpen}
          flagStatus={dirtyFlagStatus}
          caseNumber={caseEntity.caseNumber}
          onClose={() => {
            handleFlagStatusModal("", MigrationFlagStatus.UnResolved, false)
          }}
          onConfirm={async () => {
            await casesRepository.updateCaseFlag(dirtyFlagId, dirtyFlagStatus);
            await queryClient.invalidateQueries(["cases", organizationId]);
            await queryClient.invalidateQueries(["case", caseId])
            handleFlagStatusModal("", MigrationFlagStatus.UnResolved, false);
          }}
        />
        : <></>
      }
    </>
  );
};

export default CasePage;
