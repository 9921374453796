import { ColorPaletteProp } from "@mui/joy";

export enum MigrationStatus {
  NotStarted = "notStarted",
  InProgress = "inProgress",
  Finished = "finished",
  Interrupted = "interrupted",
  NotFound = "notFound",
  Flagged = "flagged",
}

export const MigrationStatusLabels: Record<MigrationStatus, string> = {
  [MigrationStatus.NotStarted]: "Not Started",
  [MigrationStatus.InProgress]: "In Progress",
  [MigrationStatus.Finished]: "Finished",
  [MigrationStatus.Interrupted]: "Interrupted",
  [MigrationStatus.NotFound]: "Not Found",
  [MigrationStatus.Flagged]: "Flagged",
};

export const MigrationStatusColors: Record<MigrationStatus, ColorPaletteProp> =
  {
    [MigrationStatus.NotStarted]: "neutral",
    [MigrationStatus.InProgress]: "warning",
    [MigrationStatus.Finished]: "success",
    [MigrationStatus.Interrupted]: "danger",
    [MigrationStatus.NotFound]: "warning",
    [MigrationStatus.Flagged]: "primary",
  };
